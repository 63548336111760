import { Loading } from "app/components/loading";
import { api } from "app/config";
import { useInfiniteDataQuery } from "app/hooks/useInfiniteDataQuery";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NotificationItem } from "./NotificationItem";
import { useNotificationReader } from "./hooks/useNotificationReader";
import { useNotificationSearchParams } from "./hooks/useNotificationSearchParams";
import { Notification } from "./types";

const PAGE_SIZE = "10";

export const NotificationList = () => {
  const searchParams = useNotificationSearchParams();

  const [notifications, updateNotifications] = useState<Notification[]>([]);
  const { isFetching, lastItemRef } = useInfiniteDataQuery<Notification, HTMLDivElement>({
    namespace: "notifications",
    url: `${api}/notifications`,
    defaultSearchParams: new URLSearchParams({
      size: PAGE_SIZE,
      ...searchParams
    }),
    onSuccess: (data) => {
      updateNotifications(data);
    }
  });

  const markAsRead = useNotificationReader(false);
  const handleNotificationItemClick =
    (notification: Notification, index: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { currentTarget } = event;
      currentTarget.querySelector("div.description").classList.toggle("clamped");
      if (!notification.read) {
        markAsRead(notification.id).catch(() => console.error("notification read failed"));
        updateNotifications((notifications) => {
          const cloned: Notification[] = [...notifications];
          cloned[index].read = true;
          return cloned;
        });
      }
    };

  return (
    <PerfectScrollbar>
      {(notifications ?? []).map((notification, index) => (
        <NotificationItem
          key={index}
          notification={notification}
          onNotificationItemClick={handleNotificationItemClick(notification, index)}
          ref={notifications.length === index + 1 ? lastItemRef : null}
        />
      ))}
      {isFetching && (
        <div>
          <Loading />
        </div>
      )}
    </PerfectScrollbar>
  );
};
