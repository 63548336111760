import { Icon } from "app/components/icon";
import { ChangeEvent, PropsWithChildren, useCallback, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useKeyPressEvent, useWindowSize } from "react-use";
import { useFilters } from "./hooks/useFilters";
import { useSortDirection } from "./hooks/useSortDirection";
import { useUpdateSearchQuery } from "./hooks/useUpdateSearchQuery";

type ControlsDropdownProps = PropsWithChildren<{
  readonly controlType: "filter" | "sort";
}>;

const ControlsDropdown = ({ controlType, children }: ControlsDropdownProps) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const variant = width >= 768 ? "outline-secondary" : "primary";
  const toggle = t(`notifications.${controlType}.${width >= 768 ? "label" : "mobileLabel"}`);

  return (
    <Dropdown className={controlType} autoClose="outside" drop="down">
      <Dropdown.Toggle variant={variant}>{toggle}</Dropdown.Toggle>
      {children}
    </Dropdown>
  );
};

const NotificationsSearch = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const updateSearch = useUpdateSearchQuery();

  const changeSearchValue = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    value === "" && updateSearch("");
  };

  const callback = useCallback(() => updateSearch(searchValue), [searchValue, updateSearch]);
  useKeyPressEvent("Enter", () => {
    // console.log("EnterKeyPressed");
    callback();
  });

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        placeholder={t("notifications.searchPlaceholder")}
        value={searchValue}
        onChange={changeSearchValue}
      />
      <span className="input-group-text" role="button" onClick={callback}>
        <Icon name="bi-search" />
      </span>
    </div>
  );
};

const NotificationFilter = () => {
  const { t } = useTranslation();
  const { filters, changeFilterValue } = useFilters();
  return (
    <ControlsDropdown controlType="filter">
      <Dropdown.Menu>
        <Dropdown.Item as="div">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="unread"
              name="unread"
              checked={filters.unread}
              onChange={changeFilterValue}
            />
            <label className="form-check-label" htmlFor="unread">
              {t("notifications.filter.dropdown.unread")}
            </label>
          </div>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="read"
              name="read"
              checked={filters.read}
              onChange={changeFilterValue}
            />
            <label className="form-check-label" htmlFor="read">
              {t("notifications.filter.dropdown.read")}
            </label>
          </div>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="info"
              name="info"
              checked={filters.info}
              onChange={changeFilterValue}
            />
            <label className="form-check-label" htmlFor="info">
              {t("notifications.filter.dropdown.info")}
            </label>
          </div>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="warning"
              name="warning"
              checked={filters.warning}
              onChange={changeFilterValue}
            />
            <label className="form-check-label" htmlFor="warning">
              {t("notifications.filter.dropdown.warning")}
            </label>
          </div>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="alert"
              name="alert"
              checked={filters.alert}
              onChange={changeFilterValue}
            />
            <label className="form-check-label" htmlFor="alert">
              {t("notifications.filter.dropdown.alert")}
            </label>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </ControlsDropdown>
  );
};

const NotificationSorter = () => {
  const { t } = useTranslation();
  const { sortDirection, changeSortDirection } = useSortDirection();
  return (
    <ControlsDropdown controlType="sort">
      <Dropdown.Menu>
        <Dropdown.Item as="div">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="sortBy"
              id="desc"
              value="desc"
              checked={sortDirection === "desc"}
              onChange={changeSortDirection}
            />
            <label className="form-check-label" htmlFor="desc">
              {t("notifications.sort.dropdown.desc")}
            </label>
          </div>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="sortBy"
              id="asc"
              value="asc"
              checked={sortDirection === "asc"}
              onChange={changeSortDirection}
            />
            <label className="form-check-label" htmlFor="asc">
              {t("notifications.sort.dropdown.asc")}
            </label>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </ControlsDropdown>
  );
};

export const NotificationsSettings = () => {
  return (
    <div className="settings">
      <NotificationsSearch />
      <div className="controls">
        <NotificationFilter />
        <NotificationSorter />
      </div>
    </div>
  );
};
