import { Alert, extractAlerts, showAlert, showAlerts, WithAlerts } from "app/utils";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHttpRequest } from "./useHttpRequest";

const commands = {
  FIX_GPS: "fixGps",
  UPDATE_FW: "updateFw",
  UPDATE_FWR: "updateFwr",
  UPDATE_FWS: "updateFws"
} as const;

export type CommandType = ValueOf<typeof commands>;

export type NotFixGps = ValueOf<Omit<typeof commands, "FIX_GPS">>;

export type CommandRequest<T> = T extends "fixGps"
  ? "fixGps"
  : T extends NotFixGps
  ? {
      command: NotFixGps;
      version?: string;
    }
  : never;

export function useDeviceCommandScheduler(deviceId: string | number, successCb?: () => void) {
  const request = useHttpRequest<Record<string, any>, WithAlerts<void>>();
  const { mutateAsync: scheduleCommand } = useMutation(
    async (cmdRequest: CommandRequest<CommandType>) => {
      const url = `/iot/devices/${deviceId}/commands`;
      const body = cmdRequest === "fixGps" ? { command: cmdRequest } : cmdRequest;
      const { data } = await request({ method: "POST", url, data: body });
      return data;
    },
    {
      onSuccess: (data: WithAlerts<void>) => {
        if (successCb) successCb();
        if (data && "alerts" in data) {
          data = data as WithAlerts<void>;
          showAlerts(extractAlerts(data.alerts));
        }
      },
      onError(error: AxiosError<any>) {
        const { detail } = error.response.data as Record<string, any>;
        if (detail) {
          showAlert({ type: Alert.ERROR, message: detail });
        }
      }
    }
  );

  return { scheduleCommand };
}
