export const it = {
  commons: {
    sorting: "Ordinamento",
    false: "No",
    true: "Sì",
    logout: "Esci",
    show: "Mostra Tutti",
    add: "Aggiungi",
    deleteItem: "Rimuovi elemento",
    view: "Visualizza",
    create: "Aggiungi",
    close: "Chiudi",
    save: "Salva",
    actions: "Azioni",
    loading: "Caricamento...",
    goHome: "Vai alla homepage",
    goBack: "Torna indietro",
    from: "Da",
    to: "A",
    confirmTitle: "Conferma per procedere",
    none: "N/D",
    none_alt: "Nessuno",
    none_alt2: "Nessuna",
    not_specified: "Non specificato",
    not_specified_alt: "Non specificata",
    not_specified_alt2: "Non specificati",
    not_specified_alt3: "Non specificate",
    min: "Minimo",
    max: "Massimo",
    mean: "Media",
    stdDev: "Dev. Std.",
    languages: {
      it: "Italiano",
      en: "Inglese"
    },
    address: {
      label: "Indirizzo",
      inputPlaceholder: "Inserisci l'indirizzo"
    },
    latitude: "Latitudine",
    longitude: "Longitudine",
    altitude: "Altitudine (metri)",
    alerts: {
      delete: "La cancellazione è un'operazione irreversibile, procedere comunque?"
    },
    errors: {
      validation: "Risolvi i seguenti errori...",
      global: "Siamo spiacenti, si è verificato un errore",
      "404": "Questa pagina non esiste",
      dateOrTimeRange: "La data di inizio deve essere maggiore o uguale a quella di fine",
      dateOrTimeInTheFuture: "La data di inizio non può essere nel futuro",
      fromDate: {
        gtEndDate: "Data di inizio maggiore di quella di fine"
      },
      toDate: {
        ltStartDate: "Data di fine minore di quella di inizio"
      },
      geolocation: {
        denied: "Autorizza l'uso della geolocalizzazione per ottenere la tua posizione attuale",
        generic:
          "Geolocalizzazione non riuscita, non siamo riusciti ad ottenere la tua posizione attuale, riprova in seguito"
      }
    },
    resetZoom: "Resetta zoom",
    durations: {
      hours: "Ore",
      minutes: "Minuti",
      seconds: "Secondi",
      milliseconds: "Millisecondi"
    },
    buttons: {
      back_to_list: "Torna alla lista"
    },
    selectAll: "Seleziona tutto",
    deselectAll: "Rimuovi tutto",
    copyToClipboard: {
      btn: "Copia testo",
      success: "Testo copiato nella clipboard",
      error: "Non è stato possibile copiare il testo nella clipboard"
    },
    sampleRate: "Tempo di campionamento",
    acquisitionRate: "Tempo di acquisizione",
    port: "Porta",
    type: "Connessione",
    isDirty: "Attenzione! Le modifiche andranno perse, vuoi continuare?",
    createdBy: {
      label: "Creato da",
      tableHeader: "$t(createdBy.label)",
      filterInputPlaceholder: "Filtera per username"
    },
    createdAt: {
      label: "Creato il",
      tableHeader: "$t(createdAt.label)",
      filterInputPlaceholder: "Filtra per data di creazione"
    },
    more: "Mostra di più",
    less: "Mostra di meno",
    setBtn: "Imposta",
    search: "Sto cercando..."
  },
  interface: {
    sidebar: {
      menus: {
        main: {
          label: "Menu principale",
          home: "Homepage",
          dashboard: "Dashboard",
          activities: "Agenda di campagna",
          fields: "Campi",
          devices: "Dispositivi",
          wirelessDevices: "Dispositivi Wireless",
          sensors: "Sensori",
          firmwares: "Firmware",
          evapotranspiration: "Evapotraspirazione",
          notifications: "Notifiche"
        },
        management: {
          label: "Gestione",
          cultures: "Colture",
          fields: "$(sidebar.menus.main.fields)"
        },
        definitions: {
          label: "Definizioni",
          groups: "Gruppi di campi",
          "culture-types": "Tipi di coltivazione",
          "field-types": "Tipo di gestione",
          granulometries: "Granulometrie",
          "irrigation-methods": "Metodi di irrigazione",
          "irrigation-sources": "Fonti di irrigazione",
          "coverage-types": "Siti di produzione",
          devices: "Dispositivi",
          variables: "Variabili",
          sensors: "Sensori",
          cultures: "Colture Agea",
          varieties: "Varietà Agea",
          productsCategories: "Categorie prodotti",
          adversities: "Avversità",
          "adversity-types": "Tipi di avversità"
        },
        administration: {
          label: "Amministrazione",
          users: "Utenti",
          generalSettings: "Impostazioni",
          profile: "Profilo aziendale",
          companies: "Profili aziendali",
          tenants: "Tenant",
          "api-keys": "Chiavi api",
          scheduled: "Job"
        },
        fertilizers: "Concimi",
        defenseProducts: "Prodotti per la difesa"
      },
      footer: {}
    },
    topbar: {
      profile: {
        items: {
          profile: "Profilo aziendale",
          notifications: "Notifiche",
          password: "Cambia password",
          settings: "Impostazioni"
        }
      }
    },
    crud: {
      table: {
        title: "Lista di",
        empty: "Nessun risultato trovato",
        toolbar: {
          toggler: "Colonne visibili",
          export: {
            label: "Esporta",
            selectedLabel: "Selezionati",
            modalHeader: "Seleziona il formato desiderato",
            choices: {
              all: "Tutti",
              currentPage: "Pagina corrente"
            }
          },
          delete: "Cancella"
        },
        filters: {
          formTitle: "Filtri disponibili",
          placeholder: "Filtra per {{columnId}}",
          submitBtn: "Cerca",
          removeBtn: "Resetta filtri",
          toggler: {
            visible: "Nascondi filtri",
            hidden: "Mostra filtri"
          }
        },
        footer: {
          select: {
            label: "Visualizza {{rowsCount}} righe"
          },
          pagination: {
            label: "Pagina {{current}} di {{total}}",
            manual: {
              label: "Cambia pagina",
              go: "Vai",
              placeholder: "Numero di pagina...",
              error: "Inserisci un valore tra 1 e {{pageCount}}"
            }
          }
        },
        show: "Mostra {{resource}}",
        edit: "Modifica {{resource}}",
        delete: "Cancella {{resource}}"
      },
      form: {
        title: {
          create: "Aggiungi {{resource}}",
          update: "Modifica",
          show: "Torna alla lista"
        },
        fields: {
          placeholder: "Inserisci {{placeholderLabel}}"
        },
        reset: "Ripristina il form",
        resetShort: "Ripristina"
      },
      export: {
        timeseries: {
          label: "Esportazione dati",
          submit: "Esporta dati",
          fields: {
            label: "Seleziona i campi da esportare",
            placeholder: "Campi"
          },
          singleFile: {
            label: "Esporta tutti i dati su singolo file"
          },
          format: {
            label: "Scegli il formato dei dati"
          }
        },
        data: {
          success: "I dati sono stati esportati correttamente",
          error: "Non è stato possibile esportare i dati dalla tabella"
        }
      },
      modal: {
        cancel: "Annulla"
      }
    },
    chart: {
      sensor: "Sensore",
      variable: "Variabile misurata",
      last: "Ultimo valore misurato",
      min: "$t(min)",
      max: "$t(max)",
      minMaxCount: "Min : Max | N. campioni",
      minMaxAvgCount: "Min : Max | Media | N. campioni",
      avg: "Media",
      count: "Numero di campioni",
      missingUoM: "U.d.M. sconosciuta"
    },
    select: {
      noOptions: "Nessuna opzione disponibile"
    },
    timeseries: {
      label: "Visualizza dati",
      refresh: "Aggiorna dati"
    },
    backToDashboard: "Torna alla dashboard"
  },
  resources: {
    "api-keys": {
      label: "chiave api",
      label_other: "chiavi api",
      createLabel: "Nuova chiave api",
      regenerate: "Rigenera chiave",
      verify: "Verifica chiave",
      enable: "Abilita chiave",
      enable_other: "Abilita chiavi",
      disable: "Disabilita chiave",
      disable_other: "Disabilita chiavi",
      delete: "Cancella chiave",
      delete_other: "Cancella chiavi",
      tenant: {
        label: "Tenant",
        name: {
          label: "$t(api-keys.tenant.label)",
          tableHeader: "$t(api-keys.tenant.label)",
          filterInputPlaceholder: "Filtra per tenant"
        },
        formLabel: "$t(api-keys.tenant.label)",
        inputPlaceholder: "Seleziona il tenant del cliente che userà la chiave api"
      },
      clientDomain: {
        label: "URL client",
        tableHeader: "$t(api-keys.clientDomain.label)",
        formLabel: "$t(api-keys.clientDomain.label)",
        inputPlaceholder: "Inserisci l'URL del client che userà la chiave api"
      },
      enabled: {
        label: "Stato",
        tableHeader: "$t(api-keys.enabled.label)"
      },
      verified: {
        label: "Verificata",
        tableHeader: "$t(api-keys.verified.label)"
      },
      createdAt: {
        label: "Data/Ora generazione",
        tableHeader: "$t(api-keys.createdAt.label)"
      },
      updatedAt: {
        label: "Data/Ora modifica",
        tableHeader: "$t(api-keys.updatedAt.label)"
      },
      publicKey: {
        label: "Chiave api",
        tableHeader: "$t(api-keys.publcKey.label)"
      }
    },
    users: {
      label: "utente",
      label_other: "utenti",
      createLabel: "Aggiungi $t(users.label)",
      updateLabel: "Modifica $t(users.label)",
      resetAccountLabel: "Resetta credenziali dell'account",
      resetAccountButton: "Resetta",
      resetAccountAlert:
        "L'operazione di reset modificherà le credenziali di accesso dell'utente, procedere comunque ?",
      firstName: {
        label: "Nome",
        tableHeader: "$t(users.firstName.label)",
        formLabel: "$t(users.firstName.label)",
        inputPlaceholder: "Inserisci il nome",
        filterInputPlaceholder: "Filtra per nome"
      },
      lastName: {
        label: "Cognome",
        tableHeader: "$t(users.lastName.label)",
        formLabel: "$t(users.lastName.label)",
        inputPlaceholder: "Inserisci il cognome",
        filterInputPlaceholder: "Filtra per cognome"
      },
      email: {
        label: "Email",
        tableHeader: "$t(users.email.label)",
        formLabel: "$t(users.email.label)",
        inputPlaceholder: "Inserisci l'email",
        filterInputPlaceholder: "Filtra per email"
      },
      tenant: {
        name: {
          label: "Tenant",
          tableHeader: "$t(users.tenant.name.label)",
          filterInputPlaceholder: "Filtra per tenant"
        },
        formLabel: "$t(users.tenant.name.label)",
        inputPlaceholder: "Inserisci tenant"
      },
      username: {
        label: "Username",
        tableHeader: "$t(users.username.label)",
        formLabel: "$t(users.username.label)",
        inputPlaceholder: "Inserisci lo username",
        filterInputPlaceholder: "Filtra per username"
      },
      roles: {
        name: {
          label: "Ruoli",
          tableHeader: "$t(users.roles.name.label)",
          filterInputPlaceholder: "Filtra per ruolo"
        },
        formLabel: "$t(users.roles.name.label)",
        inputPlaceholder: "Inserisci i ruoli"
      },
      enabled: {
        label: "Abilitato",
        tableHeader: "Stato",
        formLabel: "$t(users.enabled.label)",
        inputPlaceholder: "Abilita utente",
        filterInputPlaceholder: "Filtra per stato",
        true: "Abilitato",
        false: "Disabilitato"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(users.updatedBy.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(users.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    profile: {
      label: "profilo",
      label_other: "profilo"
    },
    credentials: {
      label: "profilo",
      createLabel: "",
      updateLabel: "Modifica password",
      password: {
        label: "Password",
        tableHeader: "",
        formLabel: "$t(credentials.password.label)",
        inputPlaceholder: "Inserisci la nuova password",
        filterInputPlaceholder: ""
      },
      confirmPassword: {
        label: "Conferma password",
        tableHeader: "",
        formLabel: "$t(credentials.confirmPassword.label)",
        inputPlaceholder: "Conferma password",
        filterInputPlaceholder: ""
      }
    },
    settings: {
      label: "Impostazioni generali",
      createLabel: " $t(settings.label)",
      updateLabel: " $t(settings.label)",
      submit: {
        success: "Impostazioni salvate con successo"
      },
      emailSettings: {
        fieldSetLabel: "Configura server mail",
        host: {
          label: "Host",
          formLabel: "Host",
          inputPlaceholder: "Indirizzo del server SMTP"
        },
        port: {
          label: "Porta",
          formLabel: "Porta",
          inputPlaceholder: "Porta del server SMTP"
        },
        senderEmail: {
          label: "Mittente",
          formLabel: "Indirizzo mittente",
          inputPlaceholder: "Inserisci l'indirizzo del mittente"
        },
        senderDisplayName: {
          label: "Mittente",
          formLabel: "Nome mittente",
          inputPlaceholder: "Inserisci il nome del mittente"
        },
        username: {
          label: "Username",
          formLabel: "Username",
          inputPlaceholder: "Nome utente"
        },
        password: {
          label: "Password",
          formLabel: "Password",
          inputPlaceholder: "Password"
        },
        enableSSL: {
          label: "SSL",
          formLabel: "",
          inputPlaceholder: "Abilita SSL"
        },
        enableStartTLS: {
          label: "StartTLS",
          formLabel: "",
          inputPlaceholder: "Abilita StartTLS"
        },
        verifyOnUpdate: {
          label: "Verifica all'aggiornamento",
          formLabel: "",
          inputPlaceholder: "Manda email di verifica dopo l'aggiornamento"
        }
      }
    },
    tenants: {
      label: "tenant",
      label_other: "tenant",
      createLabel: "Aggiungi $t(tenants.label)",
      updateLabel: "Modifica $t(tenants.label)",
      name: {
        label: "Nome",
        tableHeader: "$t(tenants.name.label)",
        formLabel: "$t(tenants.name.label)",
        inputPlaceholder: "Inserisci il nome",
        filterInputPlaceholder: "Filtra per nome"
      },
      displayName: {
        label: "Nome visualizzato",
        tableHeader: "$t(tenants.displayName.label)",
        formLabel: "Nome da visualizzare",
        inputPlaceholder: "Inserisci nome da visualizzare",
        filterInputPlaceholder: "Filtra per nome visualizzato"
      },
      hostName: {
        label: "Hostname",
        tableHeader: "$t(tenants.hostName.label)",
        formLabel: "$t(tenants.hostName.label)",
        inputPlaceholder: "Inserisci l'hostname",
        filterInputPlaceholder: "Filtra per hostname"
      },
      enabled: {
        label: "Abilitato",
        tableHeader: "Stato",
        formLabel: "$t(tenants.enabled.label)",
        inputPlaceholder: "Abilita tenant",
        filterInputPlaceholder: "Filtra per stato",
        true: "Abilitato",
        false: "Disabilitato"
      },
      issuer: {
        label: "Issuer",
        tableHeader: "$t(tenants.issuer.label)",
        formLabel: "$t(tenants.issuer.label)",
        inputPlaceholder: "Questo campo verrà settato automaticamente al salvataggio",
        filterInputPlaceholder: "Filtra per issuer"
      },
      jwksUri: {
        label: "Jwk Uri",
        tableHeader: "$t(tenants.jwksUri.label)",
        formLabel: "$t(tenants.jwksUri.label)",
        inputPlaceholder: "Questo campo verrà settato automaticamente al salvataggio",
        filterInputPlaceholder: "Filtra per jwksUri"
      },
      "updatedBy.username": {
        label: "Aggiornato Da",
        tableHeader: "$t(tenants.updatedBy.username.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(tenants.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      "createdBy.username": {
        label: "Creato Da",
        tableHeader: "$t(tenants.createdBy.username.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per creatore"
      },
      createdAt: {
        label: "Creato Il",
        tableHeader: "$t(tenants.createdAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra data creazione"
      }
    },
    scheduled: {
      label: "job",
      label_other: "job",
      start: "Avvia task",
      pause: "Metti in pausa",
      resume: "Riprendi task",
      jobName: {
        label: "Nome",
        tableHeader: "$t(scheduled.jobName.label)",
        formLabel: "$t(scheduled.jobName.label)",
        inputPlaceholder: "Inserisci il nome del job",
        filterInputPlaceholder: "Filtra per nome job"
      },
      jobFailureCause: {
        label: "Errore",
        tableHeader: "$t(scheduled.jobFailureCause.label)",
        formLabel: "$t(scheduled.jobFailureCause.label)"
      },
      id: {
        label: "Id",
        tableHeader: "$t(scheduled.id.label)",
        formLabel: "$t(scheduled.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per id del job"
      },
      jobGroup: {
        label: "Gruppo",
        tableHeader: "$t(scheduled.jobGroup.label)",
        formLabel: "$t(scheduled.jobGroup.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per gruppo"
      },
      jobDescription: {
        label: "Descrizione",
        tableHeader: "$t(scheduled.jobDescription.label)",
        formLabel: "$t(scheduled.jobDescription.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per descrizione"
      },
      triggerGroup: {
        label: "Trigger",
        tableHeader: "$t(scheduled.triggerGroup.label)",
        formLabel: "$t(scheduled.triggerName.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per trigger"
      },
      triggerType: {
        label: "Tipo",
        tableHeader: "$t(scheduled.triggerType.label)",
        formLabel: "$t(scheduled.triggerType.label)",
        inputPlaceholder: "Seleziona il tipo di trigger",
        filterInputPlaceholder: "Filtra per tipo di trigger"
      },
      jobState: {
        label: "Stato",
        tableHeader: "$t(scheduled.jobState.label)",
        formLabel: "$t(scheduled.jobState.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per stato del trigger"
      },
      prevFireTime: {
        label: "Esecuzione Precedente",
        tableHeader: "$t(scheduled.prevFireTime.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data esecuzione precedente"
      },
      nextFireTime: {
        label: "Prossima Esecuzione",
        tableHeader: "$t(scheduled.nextFireTime.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data prossima esecuzione"
      },
      startedAt: {
        label: "Iniziato Il",
        tableHeader: "$t(scheduled.startedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data di inizio"
      },
      completedAt: {
        label: "Completato Il",
        tableHeader: "$t(scheduled.completedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data di completamento"
      }
    },
    devices: {
      label: "dispositivo IoT",
      label_other: "dispositivi IoT",
      labelAlt: "I miei dispositivi IoT",
      labelView: "Dati di {{device}}",
      showButtonLabel: "Visualizza {{device}}",
      createLabel: "Aggiungi $t(devices.label)",
      updateLabel: "Modifica $t(devices.label)",
      noDataLabel: "Nessun dato trovato con i filtri attualmente forniti",
      fieldTimeseriesLabel: "Dati",
      lastTimeseriesBtnLabel: "Ultimi dati misurati",
      registerBtnLabel: "Registra dispositivo",
      registerBtnHeader: "Registra un nuovo dispositivo",
      registerSubmitLabel: "Registra",
      industrialLabelModalHeader: "Etichetta industriale",
      industrialLabelPdfError: "Errore nella generazione dell'etichetta",
      qrcodeScannerBtnLabel: "Scansiona codice QR dispositivo",
      qrcodeScanError: "Si è verificato un errore nella scansione del codice QR",
      toggleInfo: {
        true: "Nascondi info",
        false: "Mostra info"
      },
      sendNotifications: {
        true: "Attive",
        false: "Disattivate",
        tableHeader: "Notifiche",
        formLabel: "Notifiche di stato",
        inputPlaceholder: "Voglio ricevere notifiche sullo stato della centralina",
        filterInputPlaceholder: "Filtra per stato delle notifiche"
      },
      filters: {
        timeseries: {
          label: "Seleziona visibili",
          placeholder: "Variabili"
        },
        dateTimeRange: {
          label: "Periodo",
          options: {
            custom: "Personalizzato",
            today: "Oggi",
            yesterday: "Ieri",
            weekly: "Settimanale",
            monthly: "Mensile",
            yearly: "Annuale",
            last24Hours: "Ultime 24 ore",
            lastWeek: "Ultima settimana",
            lastMonth: "Ultimo mese"
          }
        },
        interval: {
          placeholder: "Seleziona intervallo",
          label: "Aggregazione",
          options: {
            none: "Nessuna",
            "15m": "15 minuti",
            "30m": "30 minuti",
            "1h": "1 ora",
            "1d": "1 giorno",
            "7d": "1 settimana",
            "15d": "2 settimane",
            "1mo": "1 mese"
          }
        }
      },
      inTest: {
        header: "Modalità test attiva",
        body: "Quando il device è in modalità test, i consumi della batteria e della banda sono superiori a quelli derivanti dal normale utilizzo"
      },
      "firmware.version": {
        label: "FW",
        exportLabel: "$t(devices.firmware.version.label)",
        tableHeader: "$t(devices.firmware.version.label)",
        formLabel: "$t(devices.firmware.version.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per versione firmware"
      },
      "radioFirmware.version": {
        label: "FW radio",
        exportLabel: "$t(devices.radioFirmware.version.label)",
        tableHeader: "$t(devices.radioFirmware.version.label)",
        formLabel: "$t(devices.radioFirmware.version.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per versione FW radio"
      },
      lastDataReceivedAt: {
        label: "Data/ora ultima trasmissione",
        tableHeader: "$t(devices.lastDataReceivedAt.label)"
      },
      hardwareVersion: {
        label: "Versione HW",
        exportLabel: "$t(devices.hardwareVersion.label)",
        tableHeader: "$t(devices.hardwareVersion.label)",
        formLabel: "$t(devices.hardwareVersion.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per versione HW"
      },
      imei: {
        label: "IMEI",
        exportLabel: "IMEI",
        tableHeader: "$t(devices.imei.label)",
        formLabel: "$t(devices.imei.label)",
        inputPlaceholder: "Inserisci l'imei",
        filterInputPlaceholder: "Filtra per imei"
      },
      serialNumber: {
        label: "Num. seriale",
        exportLabel: "$t(devices.serialNumber.label)",
        tableHeader: "$t(devices.serialNumber.label)",
        formLabel: "$t(devices.serialNumber.label)",
        inputPlaceholder: "Inserisci il numero seriale",
        filterInputPlaceholder: "Filtra per numero seriale"
      },
      macAddress: {
        label: "Indirizzo MAC",
        exportLabel: "Indirizzo MAC",
        tableHeader: "$t(devices.macAddress.label)",
        formLabel: "$t(devices.macAddress.label)",
        inputPlaceholder: "Inserisci il MAC",
        filterInputPlaceholder: "Filtra per MAC"
      },
      batteryLevel: {
        label: "Livello batteria",
        exportLabel: "$t(devices.batteryLevel.label)",
        tableHeader: "$t(devices.batteryLevel.label)",
        formLabel: "$t(devices.batteryLevel.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per livello di batteria"
      },
      solarVoltage: {
        tableHeader: "Voltaggio di ricarica"
      },
      batteryChargeStatus: {
        tableHeader: "Stato della batteria",
        undefined: "N/D",
        ok: "carica",
        charging: "in carica",
        error: "errore di ricarica"
      },
      signalPower: {
        label: "Potenza segnale",
        exportLabel: "$t(devices.signalPower.label)",
        tableHeader: "$t(devices.signalPower.label)",
        formLabel: "$t(devices.signalPower.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per potenza segnale"
      },
      simICCID: {
        label: "ICCID",
        exportLabel: "$t(devices.simICCID.label)",
        tableHeader: "$t(devices.simICCID.label)",
        formLabel: "$t(devices.simICCID.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per ICCID"
      },
      networkInfo: {
        label: "Network info",
        exportLabel: "$t(devices.networkInfo.label)",
        tableHeader: "$t(devices.networkInfo.label)",
        formLabel: "$t(devices.networkInfo.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per network info"
      },
      configuration: {
        fieldSetLabel: "Configurazione",
        transmissionRate: {
          label: "Trasmetti ogni",
          exportLabel: "$t(devices.configuration.transmissionRate.label)",
          tableHeader: "$t(devices.configuration.transmissionRate.label)",
          formLabel: "$t(devices.configuration.transmissionRate.label)",
          inputPlaceholder: "Inserisci tempo di trasmissione ",
          filterInputPlaceholder: ""
        },
        band: {
          label: "Banda",
          exportLabel: "$t(devices.configuration.band.label)",
          tableHeader: "$t(devices.configuration.band.label)",
          formLabel: "$t(devices.configuration.band.label)",
          inputPlaceholder: "Inserisci banda",
          filterInputPlaceholder: ""
        },
        simOperator: {
          label: "Operatore",
          name: {
            tableHeader: "$t(devices.configuration.simOperator.label)",
            filterInputPlaceholder: "Filtra per operatore sim"
          },
          exportLabel: "$t(devices.configuration.simOperator.label)",
          formLabel: "$t(devices.configuration.simOperator.label)",
          inputPlaceholder: "Inserisci operatore SIM"
        }
      },
      lastConfigUpdatedAt: {
        label: "Aggiornamento Config.",
        tableHeader: "$t(devices.lastConfigUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data aggiornamento config."
      },
      lastConfigTransmittedAt: {
        label: "Trasmissione config.",
        tableHeader: "$t(devices.lastConfigTransmittedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data di trasmissione della config."
      },
      lastFirmwareUpdatedAt: {
        label: "Aggiornamento FW",
        tableHeader: "$t(devices.lastFirmwareUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data aggiornamento firmware"
      },
      lastRadioFirmwareUpdatedAt: {
        label: "Aggiornamento FW radio",
        tableHeader: "$t(devices.lastRadioFirmwareUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data aggiornamento firmware radio"
      },
      lastCommandTransmittedAt: {
        label: "Trasmissione comandi",
        tableHeader: "$t(devices.lastCommandTransmittedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data di ultima trasmissione comandi"
      },
      name: {
        label: "Nome",
        exportLabel: "Nome device",
        tableHeader: "$t(devices.name.label)",
        formLabel: "$t(devices.name.label)",
        inputPlaceholder: "Inserisci il nome",
        filterInputPlaceholder: "Filtra per nome"
      },
      iotDeviceDefinition: {
        exportLabel: "Tipologia device",
        "name.it": {
          label: "Tipologia",
          tableHeader: "$t(devices.iotDeviceDefinition.name.it.label)",
          filterInputPlaceholder: "Filtra per tipologia"
        },
        formLabel: "$t(devices.iotDeviceDefinition.name.it.label)",
        inputPlaceholder: "Aggiungi tipologia al dispositivo"
      },
      tenant: {
        name: {
          label: "Cliente",
          tableHeader: "$t(devices.tenant.name.label)",
          filterInputPlaceholder: "Filtra per cliente"
        },
        formLabel: "$t(devices.tenant.name.label)",
        inputPlaceholder: "Inserisci cliente"
      },
      sensors: {
        fieldSetLabel: "Configurazione sensori collegati",
        inputPlaceholder: "Seleziona sensore da aggiungere",
        denominationPlaceholder: "Aggiungi denominazione",
        dynamicListError: "Seleziona un sensore o clicca su pulsante 'Rimuovi sensore'",
        formLabel: "Configura sensori",
        sensor: "Sensore",
        acqRate: "Acquisisci ogni",
        sampleRate: "Campiona ogni",
        sdi12Label: "Sensori con interfaccia SDI-12",
        rs485Label: "Sensori con interfaccia RS-485",
        digitalLabel: "Sensori digitali",
        ptLabel: "Sensori PT100/PT1000",
        localLabel: "Sensori analogici",
        remoteLabel: "Sensori remoti",
        remove: "",
        removeBtn: "Rimuovi sensore",
        variables: "Variabili",
        definitionCode: {
          label: "Sensori",
          tableHeader: "$t(devices.sensors.definitionCode.label)",
          formLabel: "$t(devices.sensors.definitionCode.label)",
          filterInputPlaceholder: "Filtra per sensori"
        }
      },
      address: {
        formatted: {
          label: "Indirizzo",
          tableHeader: "$t(devices.address.formatted.label)",
          inputPlaceholder: "Inserisci {{coordinate}}",
          filterInputPlaceholder: "Filtra per indirizzo"
        },
        location: {
          coordinates: {
            label: "Coordinate (Lat, Lng, Alt)",
            tableHeader: "$t(devices.address.location.coordinates.label)"
          }
        },
        formLabel: "Posizione della centralina",
        fieldSetLabel: "Geolocalizzazione",
        switchLabel: "Modifica l'indirizzo allo spostamento del marker sulla mappa"
      },
      "lastKnownLocation.coordinates": {
        label: "Posizione (Lat, Lng, Alt)",
        tableHeader: "$t(devices.lastKnownLocation.coordinates.label)",
        formLabel: "$t(devices.lastKnownLocation.coordinates.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      lastKnownLocationUpdatedAt: {
        label: "Aggiornamento Posizione",
        tableHeader: "$t(devices.lastKnownLocationUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per aggiornamento posizione"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(devices.updatedBy.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(devices.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data modifica"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      },
      cultureType: {
        label: "Tipo di coltivazione",
        tableHeader: "$t(devices.cultureType.label)",
        formLabel: "$t(devices.cultureType.label) (per il contenuto idrico del suolo)",
        inputPlaceholder: "Seleziona tipo di coltivazione"
      },
      v12: {
        tableHeader: "12V"
      }
    },
    "wireless-devices": {
      label: "dispositivo IoT wireless",
      label_other: "dispositivi IoT wireless",
      labelAlt: "I miei dispositivi IoT wireless",
      showButtonLabel: "Visualizza {{device}}",
      createLabel: "Aggiungi $t(wireless-devices.label)",
      updateLabel: "Modifica $t(wireless-devices.label)",
      firmware: {
        label: "FW",
        exportLabel: "$t(wireless-devices.firmware.label)",
        tableHeader: "$t(wireless-devices.firmware.label)",
        formLabel: "$t(wireless-devices.firmware.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per versione firmware"
      },
      macAddress: {
        label: "Indirizzo MAC",
        exportLabel: "Indirizzo MAC",
        tableHeader: "$t(wireless-devices.macAddress.label)",
        formLabel: "$t(wireless-devices.macAddress.label)",
        inputPlaceholder: "Inserisci il MAC",
        filterInputPlaceholder: "Filtra per MAC"
      },
      tenant: {
        name: {
          label: "Cliente",
          tableHeader: "$t(wireless-devices.tenant.name.label)",
          filterInputPlaceholder: "Filtra per cliente"
        },
        formLabel: "$t(devices.tenant.name.label)",
        inputPlaceholder: "Inserisci cliente"
      },
      "iotSensor.denomination": {
        label: "Sensore",
        tableHeader: "$t(wireless-devices.iotSensor.denomination.label)"
      },
      iotDevice: {
        name: {
          label: "Dispositivo",
          tableHeader: "$t(wireless-devices.iotDevice.name.label)",
          filterInputPlaceholder: "Filtra per dispositivo"
        },
        formLabel: "$t(wireless-devices.iotDevice.name.label)",
        inputPlaceholder: "Seleziona dispositivo"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(wireless-devices.updatedBy.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(wireless-devices.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filtra per data modifica"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    sensors: {
      label: "sensore IoT",
      label_other: "sensori IoT",
      labelAlt: "I miei sensori IoT",
      createLabel: "Aggiungi $t(sensors.label)",
      updateLabel: "Modifica $t(sensors.label)",
      showButtonLabel: "Visualizza {{sensors}}",
      connect: "Connetti sensore",
      disconnect: "Disconnetti sensore",
      id: {
        exportLabel: "Id sensore"
      },
      denomination: {
        label: "Nome",
        exportLabel: "$t(sensors.denomination.label)",
        tableHeader: "$t(sensors.denomination.label)",
        inputPlaceholder: "Aggiungi nome sensore",
        filterInputPlaceholder: "Filtra per nome sensore"
      },
      sensorType: {
        label: "Connessione",
        tableHeader: "$t(sensors.sensorType.label)"
      },
      tenant: {
        label: "Cliente",
        tableHeader: "$t(sensors.tenant.label)",
        filterInputPlaceholder: "Filtra per cliente"
      },
      wirelessDevice: {
        formLabel: "Dispositivo wireless",
        inputPlaceholder: "Seleziona dispositivo wireless",
        macAddress: {
          label: "MAC dispositivo Wireless",
          exportLabel: "$t(sensors.wirelessDevice.macAddress.label)",
          tableHeader: "$t(sensors.wirelessDevice.macAddress.label)"
        }
      },
      port: {
        label: "Porta",
        tableHeader: "$t(sensors.port.label)",
        filterInputPlaceholder: "Filtra per porta"
      },
      deviceName: {
        label: "Device",
        tableHeader: "$t(sensors.deviceName.label)",
        filterInputPlaceholder: "Filtra per device"
      },
      enabled: {
        label: "Stato",
        tableHeader: "$t(sensors.enabled.label)",
        filterInputPlaceholder: "Filtra per stato"
      },
      "variables.name.it": {
        label: "Variabili",
        tableHeader: "$t(sensors.variables.name.it.label)"
      },
      definitionCode: {
        label: "Codice",
        exportLabel: "Codice sensore",
        tableHeader: "$t(sensors.definitionCode.label)",
        filterInputPlaceholder: "Filtra per codice sensore"
      },
      sampleRate: {
        label: "$t(sampleRate)",
        tableHeader: "$t(sampleRate)"
      },
      acquisitionRate: {
        label: "$t(acquisitionRate)",
        tableHeader: "$t(acquisitionRate)"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(sensors.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(sensors.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data di aggiornamento"
      }
    },
    definitions: {
      variables: {
        label: "variabile IoT",
        label_other: "variabili IoT",
        createLabel: "Aggiungi $t(definitions.variables.label)",
        updateLabel: "Modifica $t(definitions.variables.label)",
        id: {
          label: "Id",
          exportLabel: "Id variabile",
          tableHeader: "$t(definitions.variables.id.label)",
          formLabel: "$t(definitions.variables.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        name: {
          exportLabel: "Nome variabile",
          fieldSetLabel: "Nome",
          it: {
            label: "Nome Italiano",
            tableHeader: "$t(definitions.variables.name.it.label)",
            formLabel: "Italiano",
            inputPlaceholder: "Inserisci il nome in italiano",
            filterInputPlaceholder: "Filtra per nome (italiano)"
          },
          en: {
            label: "Nome Inglese",
            tableHeader: "$t(definitions.variables.name.en.label)",
            formLabel: "Inglese",
            inputPlaceholder: "Inserisci il nome in inglese",
            filterInputPlaceholder: "Filtra per nome (inglese)"
          }
        },
        measureUnit: {
          label: "Unità Di Misura",
          exportLabel: "$t(definitions.variables.measureUnit.label)",
          tableHeader: "$t(definitions.variables.measureUnit.label)",
          formLabel: "Aggiungi unità di misura",
          inputPlaceholder: "Unità di misura",
          filterInputPlaceholder: "Filtra per unità di misura"
        },
        updatedBy: {
          label: "Aggiornato Da",
          tableHeader: "$t(definitions.variables.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per utente"
        },
        updatedAt: {
          label: "Aggiornato Il",
          tableHeader: "$t(definitions.variables.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per data modifica"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      sensors: {
        label: "sensore IoT",
        label_other: "sensori IoT",
        createLabel: "Aggiungi $t(definitions.sensors.label)",
        updateLabel: "Modifica $t(definitions.sensors.label)",
        "wiredDefaults.fieldSetLabel": "Configurazione locale di default",
        "wirelessDefaults.fieldSetLabel": "Configurazione remota di default",
        id: {
          label: "Id",
          tableHeader: "$t(definitions.sensors.id.label)",
          formLabel: "$t(definitions.sensors.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        sensorDefinitionCode: {
          label: "Codice sensore",
          tableHeader: "$t(definitions.sensors.sensorDefinitionCode.label)",
          formLabel: "$t(definitions.sensors.sensorDefinitionCode.label)",
          inputPlaceholder: "Inserisci codice di definizione del sensore",
          filterInputPlaceholder: "Filtra per codice sensore"
        },
        brand: {
          label: "Produttore",
          tableHeader: "$t(definitions.sensors.brand.label)",
          formLabel: "$t(definitions.sensors.brand.label)",
          inputPlaceholder: "Aggiungi il produttore del sensore",
          filterInputPlaceholder: "Filtra per produttore"
        },
        model: {
          label: "Modello",
          tableHeader: "$t(definitions.sensors.model.label)",
          formLabel: "$t(definitions.sensors.model.label)",
          inputPlaceholder: "Aggiungi il modello del sensore",
          filterInputPlaceholder: "Filtra per modello"
        },
        transducer: {
          label: "Trasduttore",
          tableHeader: "$t(definitions.sensors.transducer.label)",
          formLabel: "$t(definitions.sensors.transducer.label)",
          inputPlaceholder: "Aggiungi il trasduttore",
          filterInputPlaceholder: "Filtra per trasduttore"
        },
        dataSheetUrl: {
          label: "Url Datasheet",
          tableHeader: "$t(definitions.sensors.dataSheetUrl.label)",
          formLabel: "$t(definitions.sensors.dataSheetUrl.label)",
          inputPlaceholder: "Aggiungi l'url del datasheed",
          filterInputPlaceholder: "Filtra per datasheet url"
        },
        updatedBy: {
          label: "Aggiornato Da",
          tableHeader: "$t(definitions.sensors.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per utente"
        },
        updatedAt: {
          label: "Aggiornato Il",
          tableHeader: "$t(definitions.sensors.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per data modifica"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        },
        measuredVariables: {
          formLabel: "Variabili misurate",
          inputPlaceholder: "Aggiungi le variabili misurate",
          "name.it": {
            label: "Variabili",
            tableHeader: "$t(definitions.sensors.measuredVariables.name.it.label)",
            filterInputPlaceholder: "Filtra per variabili misurate"
          }
        },
        communicationInterfaces: {
          label: "Interfacce",
          formLabel: "Interfacce di comunicazione",
          tableHeader: "$t(definitions.sensors.communicationInterfaces.label)",
          inputPlaceholder: "Aggiungi interfacce di comunicazione",
          filterInputPlaceholder: "Filtra data interfacce di comunicazione"
        },
        defaultPort: {
          label: "Porta di default",
          tableHeader: "$t(definitions.sensors.defaultPort.label)",
          formLabel: "$t(definitions.sensors.defaultPort.label)",
          inputPlaceholder: "1",
          filterInputPlaceholder: ""
        },
        defaultBusAddress: {
          label: "Indirizzo bus di default",
          tableHeader: "$t(definitions.sensors.defaultBusAddress.label)",
          formLabel: "$t(definitions.sensors.defaultBusAddress.label)",
          inputPlaceholder: "1",
          filterInputPlaceholder: ""
        },
        defaultConfig: {
          fieldSetLabel: "",
          wiredDefaults: {
            sampleRate: {
              label: "$t(sampleRate) locale",
              tableHeader: "$t(definitions.sensors.defaultConfig.wiredDefaults.sampleRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wiredDefaults.sampleRate.tableHeader)",
              inputPlaceholder: "Inserisci tempo di campionamento locale"
            },
            acquisitionRate: {
              label: "$t(acquisitionRate) locale",
              tableHeader: "$t(definitions.sensors.defaultConfig.wiredDefaults.acquisitionRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wiredDefaults.acquisitionRate.tableHeader)",
              inputPlaceholder: "Inserisci tempo di acquisizione locale"
            }
          },
          wirelessDefaults: {
            sampleRate: {
              label: "$t(sampleRate) remoto",
              tableHeader: "$t(definitions.sensors.defaultConfig.wirelessDefaults.sampleRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wirelessDefaults.sampleRate.tableHeader)",
              inputPlaceholder: "Inserisci tempo di campionamento remoto"
            },
            acquisitionRate: {
              label: "$t(acquisitionRate) remoto",
              tableHeader: "$t(definitions.sensors.defaultConfig.wirelessDefaults.acquisitionRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wirelessDefaults.acquisitionRate.tableHeader)",
              inputPlaceholder: "Inserisci tempo di acquisizione remoto"
            }
          }
        }
      },
      devices: {
        label: "definizione dispositivo IoT",
        label_other: "definizioni dispositivi IoT",
        createLabel: "Aggiungi $t(definitions.devices.label)",
        updateLabel: "Modifica $t(definitions.devices.label)",
        deviceDefinitionCode: {
          label: "Codice device",
          tableHeader: "$t(definitions.devices.deviceDefinitionCode.label)",
          formLabel: "$t(definitions.devices.deviceDefinitionCode.label)",
          inputPlaceholder: "Inserisci codice di definizione del device",
          filterInputPlaceholder: "Filtra per codice device"
        },
        id: {
          label: "Id",
          tableHeader: "$t(definitions.devices.id.label)",
          formLabel: "$t(definitions.devices.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        name: {
          fieldSetLabel: "Nome",
          it: {
            label: "Nome Italiano",
            tableHeader: "$t(definitions.devices.name.it.label)",
            formLabel: "Italiano",
            inputPlaceholder: "Inserisci il nome in italiano",
            filterInputPlaceholder: "Filtra per nome (italiano)"
          },
          en: {
            label: "Nome Inglese",
            tableHeader: "$t(definitions.devices.name.en.label)",
            formLabel: "Inglese",
            inputPlaceholder: "Inserisci il nome in inglese",
            filterInputPlaceholder: "Filtra per nome (inglese)"
          }
        },
        defaultConfig: {
          wired: "Cavo",
          wireless: "Wireless",
          fieldSetLabel: "Configurazione di default",
          transmissionRate: {
            label: "Trasmetti ogni",
            exportLabel: "$t(definitions.devices.defaultConfig.transmissionRate.label)",
            tableHeader: "$t(definitions.devices.defaultConfig.transmissionRate.label)",
            formLabel: "$t(definitions.devices.defaultConfig.transmissionRate.label)",
            inputPlaceholder: "Inserisci tempo di trasmissione ",
            filterInputPlaceholder: ""
          },
          sensors: {
            formLabel: "Configurazione dei sensori predefiniti",
            resetEditorInput: "Annulla",
            saveEditorInput: {
              add: "Aggiungi",
              update: "Modifica"
            }
          }
        },
        "sensors.fieldSetLabel": "",
        iotDevices: {
          name: {
            label: "Dispositivi",
            tableHeader: "$t(definitions.devices.iotDevices.name.label)",
            filterInputPlaceholder: "Filtra per device"
          },
          inputPlaceholder: "Aggiungi dispositivi",
          formLabel: "Dispositivi"
        },
        updatedBy: {
          label: "Aggiornato Da",
          tableHeader: "$t(definitions.devices.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per utente"
        },
        updatedAt: {
          label: "Aggiornato Il",
          tableHeader: "$t(definitions.devices.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per data modifica"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      cultures: {
        label: "coltura da agea",
        label_other: "colture da agea",
        createLabel: "Aggiungi cultura",
        updateLabel: "Modifica coltura",
        id: {
          label: "Id",
          tableHeader: "$t(definitions.cultures.id.label)",
          formLabel: "$t(definitions.cultures.id.label) Agea",
          inputPlaceholder: "Inserisci l'id della coltura di agea",
          filterInputPlaceholder: "Filtra per id coltura"
        },
        useCode: {
          label: "Destinazione d'uso",
          tableHeader: "$t(definitions.cultures.useCode.label)",
          formLabel: "$t(definitions.cultures.useCode.label)",
          inputPlaceholder: "Inserisci il codice della destinazione d'uso agea",
          filterInputPlaceholder: "Filtra per destinazione d'uso"
        },
        name: {
          fieldSetLabel: "Nome",
          it: {
            label: "Nome Italiano",
            tableHeader: "$t(definitions.cultures.name.it.label)",
            formLabel: "Italiano",
            inputPlaceholder: "Inserisci il nome in italiano",
            filterInputPlaceholder: "Filtra per nome (italiano)"
          },
          en: {
            label: "Nome Inglese",
            tableHeader: "$t(definitions.cultures.name.en.label)",
            formLabel: "Inglese",
            inputPlaceholder: "Inserisci il nome in inglese",
            filterInputPlaceholder: "Filtra per nome (inglese)"
          }
        },
        isHerbaceous: {
          label: "Struttura vegetale",
          tableHeader: "Strutt. vegetale",
          formLabel: "$t(definitions.cultures.isHerbaceous.label)",
          inputPlaceholder: "Erbacea",
          filterInputPlaceholder: "Filtra per struttura vegetale"
        },
        correctiveFactor: {
          label: "Fattore correttivo della coltura",
          tableHeader: "$t(definitions.cultures.correctiveFactor.label)",
          formLabel: "$t(definitions.cultures.correctiveFactor.label)",
          inputPlaceholder: "Inserisci il fattore correttivo della coltura",
          filterInputPlaceholder: "Filtra per il fattore correttivo della coltura"
        },
        optimalNDVI: {
          label: "NDVI ottimale",
          tableHeader: "$t(definitions.cultures.optimalNDVI.label)",
          formLabel: "$t(definitions.cultures.optimalNDVI.label)",
          inputPlaceholder: "Inserisci il valore di NDVI ottimale",
          filterInputPlaceholder: "Filtra per destinazione d'uso"
        },
        optimalTemperature: {
          label: "Temperatura ottimale (°C)",
          tableHeader: "$t(definitions.cultures.optimalTemperature.label)",
          formLabel: "$t(definitions.cultures.optimalTemperature.label)",
          inputPlaceholder: "Inserisci la temperatura ottimale",
          filterInputPlaceholder: "Filtra per la temperatura ottimale"
        },
        rueCoefficient: {
          label: "Coefficiente d'efficienza d'uso della radiazione (g/MJ)",
          tableHeader: "$t(definitions.cultures.rueCoefficient.label)",
          formLabel: "$t(definitions.cultures.rueCoefficient.label)",
          inputPlaceholder: "Inserisci il coefficiente d'efficienza d'uso della radiazione",
          filterInputPlaceholder: "Filtra per il coefficiente d'efficienza d'uso della radiazione"
        },
        nitroConversionFactor: {
          label: "Fattore di conversione dell'azoto (kg x unità)",
          tableHeader: "$t(definitions.cultures.nitroConversionFactor.label)",
          formLabel: "$t(definitions.cultures.nitroConversionFactor.label)",
          inputPlaceholder: "Inserisci fattore di conversione dell'azoto",
          filterInputPlaceholder: "Filtra per il fattore di correzione dell'azoto"
        },
        cropCoefficients: {
          fieldSetLabel: "Coefficienti culturali di riferimento",
          initialCropCoefficient: {
            label: "Coeff. fase iniziale (Kc_ini)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.initialCropCoefficient.label)",
            formLabel: "Coefficiente colturale per fase iniziale (Kc_ini)",
            inputPlaceholder: "Inserisci valore per il coefficiente della fase iniziale",
            filterInputPlaceholder: "Filtra per coeff. fase iniziale"
          },
          midCropCoefficient: {
            label: "Coeff. fase interm. (Kc_mid)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.midCropCoefficient.label)",
            formLabel: "Coefficiente colturale per fase intermedia (Kc_med)",
            inputPlaceholder: "Inserisci valore il coefficiente della fase intermedia",
            filterInputPlaceholder: "Filtra per coeff. fase intermedia"
          },
          endCropCoefficient: {
            label: "Coeff. fase finale (Kc_end)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.endCropCoefficient.label)",
            formLabel: "Coefficiente colturale della fase finale (Kc_end)",
            inputPlaceholder: "Inserisci valore per il coefficiente della fase finale",
            filterInputPlaceholder: "Filtra per coeff. fase finale"
          }
        },
        growthStagesLengths: {
          fieldSetLabel: "Lunghezza delle fasi di crescita",
          initialSeasonLen: {
            label: "Lunghezza fase iniziale (L_ini)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase iniziale",
            filterInputPlaceholder: "Filtra lunghezza della fase iniziale"
          },
          developmentSeasonLen: {
            label: "Lunghezza fase di sviluppo (L_dev)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase di sviluppo",
            filterInputPlaceholder: "Filtra lunghezza della fase di sviluppo"
          },
          midSeasonLen: {
            label: "Lunghezza fase intermedia (L_mid)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase intermedia",
            filterInputPlaceholder: "Filtra lunghezza della fase intermedia"
          },
          endSeasonLen: {
            label: "Lunghezza fase finale (L_end)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase finale",
            filterInputPlaceholder: "Filtra lunghezza della fase finale"
          }
        },
        updatedBy: {
          label: "Aggiornato Da",
          tableHeader: "$t(definitions.cultures.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per utente"
        },
        updatedAt: {
          label: "Aggiornato Il",
          tableHeader: "$t(definitions.cultures.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per data modifica"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      varieties: {
        label: "varietà di colutura da agea",
        label_other: "varietà di colture da agea",
        createLabel: "Aggiungi varietà",
        updateLabel: "Modifica varietà",
        id: {
          label: "Id",
          tableHeader: "$t(definitions.cultures.id.label)",
          formLabel: "$t(definitions.cultures.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        varietyId: {
          label: "Codice varietà",
          tableHeader: "$t(definitions.varieties.varietyId.label)",
          formLabel: "$t(definitions.varieties.varietyId.label)",
          inputPlaceholder: "Inserisci il codice Agea della varietà",
          filterInputPlaceholder: "Filtra per codice Agea varietà"
        },
        name: {
          fieldSetLabel: "Nome",
          it: {
            label: "Nome Italiano",
            tableHeader: "$t(definitions.cultures.name.it.label)",
            formLabel: "Italiano",
            inputPlaceholder: "Inserisci il nome in italiano",
            filterInputPlaceholder: "Filtra per nome (italiano)"
          },
          en: {
            label: "Nome Inglese",
            tableHeader: "$t(definitions.cultures.name.en.label)",
            formLabel: "Inglese",
            inputPlaceholder: "Inserisci il nome in inglese",
            filterInputPlaceholder: "Filtra per nome (inglese)"
          }
        },
        culture: {
          id: {
            tableHeader: "Codice coltura",
            filterInputPlaceholder: "Filtra per il codice della coltura Agea"
          },
          "name.it": {
            label: "Coltura",
            tableHeader: "Nome coltura",
            filterInputPlaceholder: "Filtra per il nome della coltura Agea"
          },
          inputPlaceholder: "Seleziona la coltura Agea",
          formLabel: "$t(definitions.varieties.culture.name.it.label)"
        },
        cropCoefficients: {
          fieldSetLabel: "Coefficienti culturali di riferimento",
          initialCropCoefficient: {
            label: "Coeff. fase iniziale (Kc_ini)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.initialCropCoefficient.label)",
            formLabel: "Coefficiente colturale per fase iniziale (Kc_ini)",
            inputPlaceholder: "Inserisci valore per il coefficiente della fase iniziale",
            filterInputPlaceholder: "Filtra per coeff. fase iniziale"
          },
          midCropCoefficient: {
            label: "Coeff. fase interm. (Kc_mid)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.midCropCoefficient.label)",
            formLabel: "Coefficiente colturale per fase intermedia (Kc_med)",
            inputPlaceholder: "Inserisci valore il coefficiente della fase intermedia",
            filterInputPlaceholder: "Filtra per coeff. fase intermedia"
          },
          endCropCoefficient: {
            label: "Coeff. fase finale (Kc_end)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.endCropCoefficient.label)",
            formLabel: "Coefficiente colturale della fase finale (Kc_end)",
            inputPlaceholder: "Inserisci valore per il coefficiente della fase finale",
            filterInputPlaceholder: "Filtra per coeff. fase finale"
          }
        },
        growthStagesLengths: {
          fieldSetLabel: "Lunghezza delle fasi di crescita",
          initialSeasonLen: {
            label: "Lunghezza fase iniziale (L_ini)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase iniziale",
            filterInputPlaceholder: "Filtra lunghezza della fase iniziale"
          },
          developmentSeasonLen: {
            label: "Lunghezza fase di sviluppo (L_dev)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase di sviluppo",
            filterInputPlaceholder: "Filtra lunghezza della fase di sviluppo"
          },
          midSeasonLen: {
            label: "Lunghezza fase intermedia (L_mid)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase intermedia",
            filterInputPlaceholder: "Filtra lunghezza della fase intermedia"
          },
          endSeasonLen: {
            label: "Lunghezza fase finale (L_end)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            inputPlaceholder: "Inserisci lunghezza della fase finale",
            filterInputPlaceholder: "Filtra lunghezza della fase finale"
          }
        },
        updatedBy: {
          label: "Aggiornato Da",
          tableHeader: "$t(definitions.cultures.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per utente"
        },
        updatedAt: {
          label: "Aggiornato Il",
          tableHeader: "$t(definitions.cultures.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filtra per data modifica"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      }
    },
    fields: {
      allLabel: "Tutti i campi",
      addButton: "Aggiungi campo",
      label: "campo",
      label_other: "campi",
      createLabel: "Aggiungi $t(fields.label)",
      updateLabel: "Modifica $t(fields.label)",
      updateButtonLabel: "Modifica campo",
      updateDropdownItem: "$t(fields.updateButtonLabel)",
      deleteDropdownItem: "Elimina campo",
      updateButtonLabelOther: "Aggiorna informazioni",
      backButtonLabel: "Torna al campo",
      backButtonLabel_other: "Torna ai campi",
      generalInformation: "Informazioni generali",
      goBackToField: "Torna al campo",
      editInfoLabel: "Modifica informazioni aggiuntive",
      searchBarPlaceholder: "Cerca per nome del campo o gruppo...",
      getDirections: "Indicazioni stradali",
      table: {
        imei: "IMEI",
        device: "Device",
        battery: "Batteria"
      },
      area: {
        label: "Superficie del campo",
        tableHeader: "$t(fields.area.label) (m²)",
        formLabel: "$t(fields.area.label)",
        inputPlaceholder: "Inserisci la superficie del campo"
      },
      name: {
        label: "Nome del campo",
        formLabel: "$t(fields.name.label)",
        inputPlaceholder: "Inserisci il nome del campo"
      },
      group: {
        label: "Gruppo",
        formLabel: "Gruppo di appartenenza",
        inputPlaceholder: "Seleziona o crea il gruppo di appartenenza"
      },
      iotDevices: {
        label: "Dispositivi",
        formLabel: "$t(fields.iotDevices.label)",
        inputPlaceholder: "Aggiungi dispositivi al campo",
        selectPlacelholder: "Seleziona dispositivo"
      },
      tenant: {
        label: "Cliente",
        formLabel: "$t(fields.tenant.label)",
        inputPlaceholder: "Seleziona cliente"
      },
      soilGranulometry: {
        label: "Granulometria",
        summaryLabel: "$t(fields.soilGranulometry.label)",
        formLabel: "$t(fields.soilGranulometry.label)",
        inputPlaceholder: "Seleziona la granulometria del suolo"
      },
      coverageType: {
        label: "Sito di produzione",
        summaryLabel: "$t(fields.coverageType.label)",
        formLabel: "$t(fields.coverageType.label)",
        inputPlaceholder: "Seleziona sito di produzione"
      },
      productionBatchCode: {
        label: "Lotto di produzione",
        summaryLabel: "$t(fields.productionBatchCode.label)",
        formLabel: "$t(fields.productionBatchCode.label)",
        inputPlaceholder: "Aggiungi lotto di produzione",
        filterInputPlaceholder: "Filtra per lotto di produzione"
      },
      type: {
        label: "Tipo di gestione",
        summaryLabel: "$t(fields.type.label)",
        formLabel: "$t(fields.type.label)",
        inputPlaceholder: "Seleziona il tipo di gestione del campo"
      },
      address: {
        label: "Indirizzo",
        formLabel: "$t(fields.address.label)",
        inputPlaceholder: "Inserisci l'indirizzo"
      },
      cadastralParticles: {
        label: "Dati catastali",
        addBtn: "Scarica dal catasto",
        getBtn: "$t(fields.cadastralParticles.addBtn)",
        updateBtn: "Aggiorna dati catastali",
        editBtn: "Modifica dati catastali",
        saveBtn: "Salva dati catastali",
        fetchError: "Non è stato possibile ottenere i dati catastali dall'agenzia delle entrate, riprova più tardi",
        formLabel: "Aggiungi i dati manualmente",
        addParticleBtn: "Aggiungi particella",
        place: "Città",
        locality: { label: "Città", inputPlaceholder: "ROMA", valid: "deve avere un valore valido" },
        district: { label: "Provincia", inputPlaceholder: "RM", valid: "deve avere due caratteri" },
        sheet: { label: "Foglio", inputPlaceholder: "1178", valid: "deve essere un numero" },
        particle: { label: "Particella", inputPlaceholder: "125", valid: "deve essere un numero" }
      },
      soilProperties: {
        label: "Caratteristiche del suolo",
        formLabel: "",
        fieldSetLabel: "",
        outputFieldSetLabel: "Costanti idrologiche",
        hydrologicalConstantsCfgBtnLabel: "Configura costanti idrologiche",
        hydrologicalConstantsBtnLabel: "Calcola",
        configure: "Configura",
        confirm:
          "L'operazione sovrascriverà i dati delle costanti idrologiche ed eventualmente della densità apparente. Procedere comunque?",
        soilDataLabel: "Suolo",
        waterDataLabel: "Acqua",
        selectMoistureSensorPlaceholder: "Seleziona sensore di umidità",
        vwc: {
          label: "Umidità del suolo (VWC)",
          updatedAt: "Aggiornato il",
          time: "alle",
          ok: "Quantità d'acqua ottimale",
          reduce: "Ridurre la quantità d'acqua",
          alert: "Attenzione, irrigare immediatamente"
        },
        soilTexture: {
          label: "Tessitura",
          formLabel: "Classe tessiturale USDA",
          inputPlaceholder: "Seleziona tessitura"
        },
        clay: {
          label: "Argilla",
          formLabel: "$t(fields.soilProperties.clay.label)",
          inputPlaceholder: "Inserisci percentuale di argilla"
        },
        sand: {
          label: "Sabbia",
          formLabel: "$t(fields.soilProperties.sand.label)",
          inputPlaceholder: "Inserisci percentuale di sabbia"
        },
        silt: {
          label: "Limo"
        },
        organicMatter: {
          label: "Sostanza organica",
          formLabel: "$t(fields.soilProperties.organicMatter.label)",
          inputPlaceholder: "Inserisci la percentuale di sostanza organica"
        },
        electricalConductivity: {
          label: "EC estratto a saturazione (ECe)",
          // label: "Conducibilità Elettrica estratto a saturazione (ECe)",
          formLabel: "$t(fields.soilProperties.electricalConductivity.label)",
          inputPlaceholder: "Inserisci la conducibilità elettrica dell'estratto a saturazione (ECe)"
        },
        gravel: {
          label: "Scheletro",
          formLabel: "$t(fields.soilProperties.gravel.label)",
          inputPlaceholder: "Inserisci percentuale di scheletro"
        },
        compaction: {
          label: "Compattazione",
          formLabel: "$t(fields.soilProperties.compaction.label)",
          inputPlaceholder: "Inserisci compattazione"
        },
        fieldCapacity: {
          label: "Capacità di campo",
          formLabel: "$t(fields.soilProperties.fieldCapacity.label)",
          inputPlaceholder: "...",
          progressBarLabel: "Stato ottimale: {{wiltingPoint}} < VWC ≤ {{fieldCapacity}} "
        },
        wiltingPoint: {
          label: "Punto di appassimento permanente",
          formLabel: "$t(fields.soilProperties.wiltingPoint.label)",
          inputPlaceholder: "...",
          progressBarLabel: "Stato critico irreversibile: VWC ≤ {{wiltingPoint}}"
        },
        saturation: {
          label: "Saturazione",
          formLabel: "$t(fields.soilProperties.saturation.label)",
          inputPlaceholder: "...",
          progressBarLabel: "Stato di sofferenza: VWC > {{fieldCapacity}}"
        },
        availableWater: {
          label: "Acqua disponibile",
          formLabel: "$t(fields.soilProperties.availableWater.label)",
          inputPlaceholder: "..."
        },
        saturatedHydraulicConductivity: {
          label: "Conducibilità idraulica a saturazione",
          formLabel: "$t(fields.soilProperties.saturatedHydraulicConductivity.label)",
          inputPlaceholder: "..."
        },
        bulkDensity: {
          label: "Densità apparente",
          formLabel: "$t(fields.soilProperties.bulkDensity.label)",
          inputPlaceholder: "..."
        },
        ph: {
          label: "pH",
          formLabel: "$t(fields.soilProperties.ph.label)",
          inputPlaceholder: "..."
        },
        activeLimestone: {
          label: "Calcare attivo",
          formLabel: "$t(fields.soilProperties.activeLimestone.label)",
          inputPlaceholder: "..."
        },
        potassiumOxide: {
          label: "Ossido di potassio (K₂O)",
          formLabel: "$t(fields.soilProperties.potassiumOxide.label)",
          inputPlaceholder: "..."
        },
        phosphoricAnhydride: {
          label: "Anidride fosforica (P₂O₅)",
          formLabel: "$t(fields.soilProperties.phosphoricAnhydride.label)",
          inputPlaceholder: "..."
        }
      }
    },
    groups: {
      label: "gruppo",
      label_other: "Gruppi di campi",
      createLabel: "Aggiungi $t(groups.label)",
      updateLabel: "Modifica $t(groups.label)",
      name: {
        label: "Nome",
        tableHeader: "$t(groups.name.label)",
        formLabel: "$t(groups.name.label)",
        inputPlaceholder: "Inserisci il nome del gruppo",
        filterInputPlaceholder: "Filtra per nome del gruppo"
      },
      fields: {
        name: {
          label: "Campi",
          tableHeader: "$t(groups.fields.name.label)",
          filterInputPlaceholder: "Filtra per campi"
        },
        formLabel: "$t(groups.fields.name.label)",
        inputPlaceholder: "Aggiungi campi al gruppo"
      },
      tenant: {
        name: {
          label: "Cliente",
          tableHeader: "$t(groups.tenant.name.label)",
          filterInputPlaceholder: "Filtra per cliente"
        },
        formLabel: "$t(groups.tenant.name.label)",
        inputPlaceholder: "Inserisci cliente"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(groups.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(groups.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    firmwares: {
      label: "Firmware",
      label_other: "Firmware",
      update: {
        label: "Aggiorna firmware",
        device: "Device",
        radio: "Trasmettitore"
      },
      firmwareType: {
        label: "Tipologia",
        tableHeader: "$t(firmwares.firmwareType.label)",
        filterInputPlaceholder: "Filtra per tipologia"
      },
      version: {
        label: "Versione",
        tableHeader: "$t(firmwares.version.label)",
        filterInputPlaceholder: "Filtra per versione"
      },
      fileName: {
        label: "Nome file",
        tableHeader: "$t(firmwares.fileName.label)",
        filterInputPlaceholder: "Filtra per nome file"
      },
      fileUrl: {
        label: "Url",
        tableHeader: "$t(firmwares.fileUrl.label)",
        filterInputPlaceholder: "Filtra per url"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(firmwares.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(firmwares.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "Creato Da",
        tableHeader: "$t(firmwares.createdBy.label)",
        filterInputPlaceholder: "Filtra per creatore"
      },
      createdAt: {
        label: "Creato Il",
        tableHeader: "$t(firmwares.createdAt.label)",
        filterInputPlaceholder: "Filtra data creazione"
      }
    },
    "culture-types": {
      label: "Tipo di coltivatione",
      label_other: "Tipi di coltivazione",
      createLabel: "Aggiungi $t(culture-types.label)",
      updateLabel: "Modifica $t(culture-types.label)",
      id: {
        label: "Id",
        tableHeader: "$t(culture-types.id.label)",
        formLabel: "$t(culture-types.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(culture-types.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(culture-types.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(culture-types.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(culture-types.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      }
    },
    "field-types": {
      label: "Tipo di gestione",
      label_other: "Tipo di gestione",
      createLabel: "Aggiungi $t(field-types.label)",
      updateLabel: "Modifica $t(field-types.label)",
      id: {
        label: "Id",
        tableHeader: "$t(field-types.id.label)",
        formLabel: "$t(field-types.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(field-types.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(field-types.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(field-types.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(field-types.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "coverage-types": {
      label: "Sito di produzione",
      label_other: "Siti di produzione",
      createLabel: "Aggiungi $t(coverage-types.label)",
      updateLabel: "Modifica $t(coverage-types.label)",
      id: {
        label: "Id",
        tableHeader: "$t(coverage-types.id.label)",
        formLabel: "$t(coverage-types.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(coverage-types.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(coverage-types.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(coverage-types.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(coverage-types.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "irrigation-methods": {
      label: "Metodo di irrigazione",
      label_other: "Metodi di irrigazione",
      createLabel: "Aggiungi $t(irrigation-methods.label)",
      updateLabel: "Modifica $t(irrigation-methods.label)",
      id: {
        label: "Id",
        tableHeader: "$t(irrigation-methods.id.label)",
        formLabel: "$t(irrigation-methods.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(irrigation-methods.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(irrigation-methods.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(irrigation-methods.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(irrigation-methods.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "irrigation-sources": {
      label: "Fonte di irrigazione",
      label_other: "Fonti di irrigazione",
      createLabel: "Aggiungi $t(irrigation-sources.label)",
      updateLabel: "Modifica $t(irrigation-sources.label)",
      id: {
        label: "Id",
        tableHeader: "$t(irrigation-sources.id.label)",
        formLabel: "$t(irrigation-sources.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(irrigation-sources.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(irrigation-sources.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(irrigation-sources.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(irrigation-sources.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "adversity-types": {
      label: "Tipo di avversità",
      label_other: "Tipi di avversità",
      createLabel: "Aggiungi $t(adversity-types.label)",
      updateLabel: "Modifica $t(adversity-types.label)",
      id: {
        label: "Id",
        tableHeader: "$t(adversity-types.id.label)",
        formLabel: "$t(adversity-types.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(adversity-types.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(adversity-types.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(adversity-types.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(adversity-types.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    adversities: {
      label: "Avversità",
      label_other: "Avversità",
      createLabel: "Aggiungi $t(adversities.label)",
      updateLabel: "Modifica $t(adversities.label)",
      id: {
        label: "Id",
        tableHeader: "$t(adversities.id.label)",
        formLabel: "$t(adversities.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(adversities.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(adversities.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      type: {
        name: {
          it: {
            label: "Tipologia",
            tableHeader: "$t(adversities.type.name.it.label)",
            filterInputPlaceholder: "Filtra per tipo di avversità"
          }
        },
        formLabel: "Tipo di avversità",
        inputPlaceholder: "Seleziona il tipo di avversità"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(adversities.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(adversities.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    granulometries: {
      label: "Granulometria del suolo",
      label_other: "Granulometrie del suolo",
      createLabel: "Aggiungi $t(granulometries.label)",
      updateLabel: "Modifica $t(granulometries.label)",
      id: {
        label: "Id",
        tableHeader: "$t(granulometries.id.label)",
        formLabel: "$t(granulometries.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(granulometries.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(granulometries.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(granulometries.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(granulometries.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    cultures: {
      addButton: "Aggiungi",
      label: "Coltura",
      label_other: "Colture",
      label_alt: "Pianta",
      viewLabelLink: "Visualizza coltura",
      createLabel: "Aggiungi $t(cultures.label)",
      updateLabel: "Modifica $t(cultures.label)",
      updateDropdownItem: "Modifica coltura",
      deleteDropdownItem: "Cancella coltura",
      historyBtnLabel: "Aggiungi a storico",
      generalInformation: "Informazioni",
      economicData: "Dati economici",
      implant: "Impianto di piantumazione",
      totalCultivatedArea: "Superficie totale coltivata",
      caltulatedPlantedUnits: "Unità calcolate",
      fieldNamePrefix: "Campo",
      fieldAreaHaPrefix: "Superficie totale",
      formInputPlaceholder: "Seleziona una coltura",
      cycleStartedAt: {
        label: "Inizio ciclo",
        tableHeader: "$t(cultures.cycleStartedAt.label)",
        formLabel: "$t(cultures.cycleStartedAt.label)",
        inputPlaceholder: "Seleziona la data di inizio ciclo",
        filterInputPlaceholder: "Filtra per data di inizio ciclo"
      },
      cycleEndedAt: {
        label: "Fine ciclo",
        tableHeader: "$t(cultures.cycleEndedAt.label)",
        formLabel: "$t(cultures.cycleEndedAt.label)",
        inputPlaceholder: "Seleziona la data di fine ciclo",
        filterInputPlaceholder: "Filtra per data di fine ciclo"
      },
      cultivatedCulture: {
        fieldSetLabel: "Dettagli sulla coltura",
        label: "Coltura",
        formLabel: "$t(cultures.cultivatedCulture.label)",
        inputPlaceholder: "Seleziona la coltura",
        "name.it": {
          label: "Coltura",
          tableHeader: "$t(cultures.cultivatedCulture.name.it.label)",
          filterInputPlaceholder: "Filtra per cultura"
        }
      },
      cultivatedCultureVariety: {
        label: "Varietà",
        formLabel: "$t(cultures.cultivatedCultureVariety.label)",
        inputPlaceholder: "Seleziona la varietà",
        "name.it": {
          label: "Varietà",
          tableHeader: "$t(cultures.cultivatedCultureVariety.name.it.label)",
          filterInputPlaceholder: "Filtra per varietà"
        }
      },
      area: {
        label: "Superficie coltivata",
        formLabel: "$t(cultures.area.label)",
        inputPlaceholder: "Inserisci la superficie coltivata in percentuale rispetto alla superficie totale del campo"
      },
      seedCost: {
        label: "Costo seme (€/kg)",
        formLabel: "Costo del seme",
        inputPlaceholder: "Inserisci il costo del seme"
      },
      plantCost: {
        label: "Costo pianta (€)",
        formLabel: "Costo della pianta",
        inputPlaceholder: "Inserisci il costo della pianta"
      },
      expectedYield: {
        label: "Resa prevista (q/ha)",
        formLabel: "Resa prevista",
        inputPlaceholder: "Inserisci la resa prevista"
      },
      yield: {
        label: "Resa (q/ha)",
        formLabel: "Resa",
        inputPlaceholder: "Inserisci la resa"
      },
      expectedSalesPrice: {
        label: "Prezzo vendita prev. (€/q)",
        formLabel: "Prezzo di vendita previsto",
        inputPlaceholder: "Inserisci il prezzo di vendita previsto"
      },
      salesPrice: {
        label: "Prezzo vendita (€/q)",
        formLabel: "Prezzo di vendita",
        inputPlaceholder: "Inserisci il prezzo di vendita"
      },
      rowsDistance: {
        label: "Distanza file (m)",
        formLabel: "Distanza tra le file",
        inputPlaceholder: "Inserisci la distanza tra le file"
      },
      plantsDistance: {
        label: "Distanza piante (m)",
        formLabel: "Distanza tra le piante",
        inputPlaceholder: "Inserisci la distanza tra le piante"
      },
      rowsInClusters: {
        label: "File nelle bine",
        formLabel: "Numero di file nelle bine",
        inputPlaceholder: "Inserisci il numero di file nelle bine"
      },
      distanceBetweenClusters: {
        label: "Dist. tra bine (m)",
        formLabel: "Distanza tra le bine",
        inputPlaceholder: "Inserisci la distanza tra le bine"
      },
      plantingSpacing: {
        label: "Sesto d'impianto (m²)",
        formLabel: "Sesto d'impianto",
        inputPlaceholder: "Inserisci il sesto d'impianto"
      },
      plantedUnits: {
        label: "Unità piantate",
        tableHeader: "$t(cultures.plantedUnits.label)",
        formLabel: "$t(cultures.plantedUnits.label)",
        formLabelAlt: "Piante per ettaro",
        inputPlaceholder: "Inserisci il numero di unità piantate"
      },
      experimental: {
        label: "Sperimentale",
        formLabel: "Extra",
        inputPlaceholder: "E' una coltura sperimentale",
        true: "Sì",
        false: "No"
      },
      requiresTransplant: {
        formLabel: "Azione richiesta",
        true: "Trapianto",
        false: "Semina"
      },
      status: {
        label: "Stato"
      },
      irrigation: {
        fieldSetLabel: "Irrigazione",
        method: {
          label: "Metodo di irrigazione",
          summaryLabel: "$t(cultures.irrigation.method.label)",
          formLabel: "$t(cultures.irrigation.method.label)",
          inputPlaceholder: "Seleziona metodo di irrigazione"
        },
        source: {
          label: "Fonte di irrigazione",
          summaryLabel: "$t(cultures.irrigation.source.label)",
          formLabel: "$t(cultures.irrigation.source.label)",
          inputPlaceholder: "Seleziona fonte di irrigazione"
        },
        implantProperties: {
          label: "Proprietà dell'impianto di irrigazione",
          fieldSetLabel: "$t(cultures.irrigation.implantProperties.label)",
          sprinklerFlowRate: {
            label: "Portata nominale irrigatore",
            formLabel: "$t(cultures.irrigation.implantProperties.sprinklerFlowRate.label)",
            inputPlaceholder: "Inserisci la portata nominale irrigatore"
          },
          efficiency: {
            label: "Efficienza",
            formLabel: "$t(cultures.irrigation.implantProperties.efficiency.label)",
            inputPlaceholder: "Inserisci la percentuale di efficienza"
          },
          distanceBetweenSprinklers: {
            label: "Distanza tra gli irrigatori",
            formLabel: "$t(cultures.irrigation.implantProperties.distanceBetweenSprinklers.label)",
            inputPlaceholder: "Inserisci la distanza tra gli irrigatori"
          },
          sprinklersPerPlantCount: {
            label: "Numero di irrigatori per pianta",
            formLabel: "$t(cultures.irrigation.implantProperties.sprinklersPerPlantCount.label)",
            inputPlaceholder: "Inserisci il numero di irrigatori per pianta"
          }
        }
      },
      plantPassport: {
        formLabelMissing: "Aggiungi certificato vivaistico",
        formLabelExisting: "Certificato vivaistico"
      }
    },
    device: {
      inTest: {
        header: "Modalità test attiva",
        body: "Quando il device è in modalità test, i consumi della batteria e della banda sono superiori a quelli derivanti dal normale utilizzo"
      },
      gpsFix: "Aggiorna posizione gps",
      viewIndustrialLabel: "Visualizza etichetta industriale",
      firmwareUpdates: {
        available: {
          header: "Aggiornamento firmware disponibile",
          body: "Una nuova versione dei firmware per il dispositivo è disponibile",
          button: "Aggiorna firmware",
          prompt:
            "Cliccando su \"Aggiorna firmware\" verrà programmato l'aggiornamento dei firmware del dispositivo.\nL'operazione non può essere annullata.\nSi ricorda di non spegnere il dispositivo durante l'aggiornamento"
        },
        scheduled: {
          header: "Aggiornamento firmware programmato",
          body: "L'aggiornamento dei firmware è stato programmato e verrà eseguito a breve.\nSe il dispositivo è in modalità di test l'aggiornamento verrà avviato una volta che il dispositivo sarà tornato in modalità normale.\nSi ricorda di non spegnere il dispositivo durante l'aggiornamento."
        },
        pending: {
          header: "Aggiornamento firmware in esecuzione",
          body: "L'aggiornamento dei firmware è in esecuzione.\nSi ricorda di non spegnere il dispositivo.\nE' necessario attendere il completamento dell'aggiornamento prima di apportare qualsiasi modifica alla configurazione."
        }
      },
      firmwareInstall: {
        header: "Installa firmware",
        button: "$t(device.firmwareInstall.header)",
        type: {
          label: "Tipo",
          inputPlaceholder: "Seleziona il tipo di firmware"
        },
        version: {
          label: "Versione",
          inputPlaceholder: "Seleziona la versione da installare"
        }
      },
      info: {
        label: "Informazioni sul device"
      },
      notes: {
        btn: {
          false: "Mostra note",
          true: "Nascondi note",
          save: "Salva note"
        },
        modal: {
          header: "Aggiungi note sulla centralina"
        },
        save: {
          success: "Note modificate correttamente",
          error: "Non è stato possibile modificare le note, riprovare in seguito"
        }
      },
      migrate: {
        btn: "Trasferisci dati",
        header: "Trasferisci i dati su un'altra stazione",
        form: {
          label: "Stazione di destinazione",
          inputPlaceholder: "Seleziona una stazione",
          submit: "Trasferisci"
        }
      },
      gallery: {
        gotoBtn: "Galleria",
        editorOpen: {
          false: "Modifica galleria",
          true: "Termina modifiche"
        },
        empty: {
          viewer: "La galleria è vuota",
          manager: 'La galleria è vuota. Clicca su "Modifica galleria" per caricare  una o più immagini',
          inEditor: "Carica una o più immagini cliccando o trascinandole sul box qui sopra"
        }
      }
    },
    companies: {
      label: "profilo aziendale",
      label_other: "profili aziendali",
      createLabel: "Aggiungi $t(companies.label)",
      updateLabel: "Modifica $t(companies.label)",
      updateBtn: "$t(companies.updateLabel)",
      goBack: "Torna indietro",
      goBackToProfile: "Torna al profilo",
      info: "Informazioni principali",
      equalAddresses: "Sede legale/operativa",
      equalAddressesLabel: "Indirizzo sede legale/operativa",
      showUsers: "Aggiungi un nuovo utente",
      address: {
        streetAddress: {
          inputPlaceholder: "Inserisci indirizzo"
        },
        postalCode: {
          formLabel: "CAP",
          inputPlaceholder: "Inserisci CAP"
        },
        locality: {
          formLabel: "Città",
          inputPlaceholder: "Seleziona la città"
        },
        region: {
          formLabel: "Provincia",
          inputPlaceholder: "Seleziona la provincia"
        },
        country: {
          formLabel: "Stato/Paese",
          inputPlaceholder: "Seleziona lo stato/paese"
        },
        countryCode: {
          formLabel: "Codice stato/paese"
        }
      },
      companyType: {
        label: "Tipo di azienda",
        tableHeader: "Tipo",
        formLabel: "$t(companies.companyType.label)",
        inputPlaceholder: "Seleziona il tipo di azienda",
        filterInputPlaceholder: "Filtra per tipo di azienda"
      },
      firstName: {
        label: "Nome",
        tableHeader: "Nome",
        formLabel: "$t(companies.firstName.label)",
        inputPlaceholder: "Inserisci nome",
        filterInputPlaceholder: "Filtra per nome"
      },
      lastName: {
        label: "Cognome",
        tableHeader: "$t(companies.lastName.label)",
        formLabel: "$t(companies.lastName.label)",
        inputPlaceholder: "Inserisci cognome",
        filterInputPlaceholder: "Filtra per cognome"
      },
      denomination: {
        label: "Ragione sociale",
        tableHeader: "Rag. sociale",
        formLabel: "$t(companies.denomination.label)",
        inputPlaceholder: "Inserisci ragione sociale",
        filterInputPlaceholder: "Filtra per ragione sociale"
      },
      vatNumber: {
        label: "Partita IVA",
        tableHeader: "P. IVA",
        formLabel: "$t(companies.vatNumber.label)",
        inputPlaceholder: "Inserisci p. IVA",
        filterInputPlaceholder: "Filtra per P.IVA"
      },
      fiscalNumber: {
        label: "Codice fiscale",
        tableHeader: "Cod. fiscale",
        formLabel: "$t(companies.fiscalNumber.label)",
        inputPlaceholder: "Inserisci codice fiscale",
        filterInputPlaceholder: "Filtra per codice fiscale"
      },
      reaNumber: {
        label: "Numero REA",
        tableHeader: "N. REA",
        formLabel: "$t(companies.reaNumber.label)",
        inputPlaceholder: "Inserisci numero REA",
        filterInputPlaceholder: "Filtra per numero REA"
      },
      cuaa: {
        label: "CUAA",
        formLabel: "$t(companies.cuaa.label)",
        tableHeader: "$t(companies.cuaa.label)",
        inputPlaceholder: "Inserisci CUAA",
        filterInputPlaceholder: "Filtra per CUAA"
      },
      tenant: {
        label: "Tenant",
        formLabel: "$t(companies.tenant.label)",
        inputPlaceholder: "Inserisci tenant del cliente",
        name: {
          label: "$t(companies.tenant.label)",
          tableHeader: "$t(companies.tenant.label)",
          filterInputPlaceholder: "Filtra per tenant"
        }
      },
      phoneNumber: {
        label: "Telefono",
        formLabel: "Numero di telefono",
        tableHeader: "$t(companies.phoneNumber.label)",
        inputPlaceholder: "Inserisci numero di telefono",
        filterInputPlaceholder: "Filtra per numero di telefono"
      },
      email: {
        label: "Indirizzo email",
        tableHeader: "Email",
        formLabel: "$t(companies.email.label)",
        inputPlaceholder: "Inserisci email",
        filterInputPlaceholder: "Filtra per email"
      },
      certifiedEmail: {
        label: "Indirizzo PEC",
        tableHeader: "PEC",
        formLabel: "$t(companies.certifiedEmail.label)",
        inputPlaceholder: "Inserisci indirizzo PEC",
        filterInputPlaceholder: "Filtra per PEC"
      },
      registeredOfficeAddress: {
        label: "Sede legale",
        fieldSetLabel: "",
        formLabel: "$t(companies.registeredOfficeAddress.label)",
        formatted: {
          label: "$t(companies.registeredOfficeAddress.label)",
          formLabel: "$t(companies.registeredOfficeAddress.label)",
          tableHeader: "$t(companies.registeredOfficeAddress.label)",
          filterInputPlaceholder: "Filtra per sede legale"
        },
        streetAddress: {
          formLabel: "Indirizzo",
          inputPlaceholder: "$t(companies.address.streetAddress.inputPlaceholder)"
        },
        postalCode: {
          formLabel: "$t(companies.address.postalCode.formLabel)",
          inputPlaceholder: "$t(companies.address.postalCode.inputPlaceholder)"
        },
        locality: {
          formLabel: "$t(companies.address.locality.formLabel)",
          inputPlaceholder: "$t(companies.address.locality.inputPlaceholder)"
        },
        region: {
          formLabel: "$t(companies.address.region.formLabel)",
          inputPlaceholder: "$t(companies.address.region.inputPlaceholder)"
        },
        country: {
          formLabel: "$t(companies.address.country.formLabel)",
          inputPlaceholder: "$t(companies.address.country.inputPlaceholder)"
        },
        countryCode: {
          formLabel: "$t(companies.address.countryCode.formLabel)"
        }
      },
      headquartersAddress: {
        label: "Sede operativa",
        fieldSetLabel: "",
        formLabel: "$t(companies.headquartersAddress.label)",
        formatted: {
          label: "$t(companies.headquartersAddress.label)",
          formLabel: "$t(companies.headquartersAddress.label)",
          tableHeader: "$t(companies.headquartersAddress.label)",
          filterInputPlaceholder: "Filtra per sede operativa"
        },
        streetAddress: {
          formLabel: "Indirizzo della sede operativa",
          inputPlaceholder: "$t(companies.address.streetAddress.inputPlaceholder)"
        },
        postalCode: {
          formLabel: "$t(companies.address.postalCode.formLabel)",
          inputPlaceholder: "$t(companies.address.postalCode.inputPlaceholder)"
        },
        locality: {
          formLabel: "$t(companies.address.locality.formLabel)",
          inputPlaceholder: "$t(companies.address.locality.inputPlaceholder)"
        },
        region: {
          formLabel: "$t(companies.address.region.formLabel)",
          inputPlaceholder: "$t(companies.address.region.inputPlaceholder)"
        },
        country: {
          formLabel: "$t(companies.address.country.formLabel)",
          inputPlaceholder: "$t(companies.address.country.inputPlaceholder)"
        },
        countryCode: {
          formLabel: "$t(companies.address.countryCode.formLabel)"
        }
      },
      registeredOfficeEqualsHeadquartersAddress: {
        label: "La sede legale coincide con quella operativa",
        formLabel: "",
        inputPlaceholder: "$t(companies.registeredOfficeEqualsHeadquartersAddress.label)"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(companies.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(companies.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    notifications: {
      label: "Notifica",
      label_other: "Notifiche",
      searchPlaceholder: "Cerca tra le notifiche",
      filter: {
        label: "Filtra per",
        mobileLabel: "Filtra",
        dropdown: {
          unread: "Non lette",
          read: "Lette",
          info: "Info",
          warning: "Warning",
          alert: "Alert"
        }
      },
      sort: {
        label: "Ordina per",
        mobileLabel: "Ordina",
        dropdown: {
          desc: "Data decrescente",
          asc: "Data crescente"
        }
      },
      markAllAsRead: "Segna tutte come lette"
    },
    management: {
      cultures: {
        label: "$t(cultures.label)",
        label_other: "$t(cultures.label_other)",
        createLabel: "$t(cultures.createLabel)",
        updateLabel: "$t(cultures.updateLabel)",
        id: {
          label: "id",
          tableHeader: "$t(management.cultures.id.label)"
        },
        cycleStartedAt: {
          label: "$t(cultures.cycleStartedAt.label)",
          tableHeader: "Data inizio ciclo",
          formLabel: "$t(cultures.cycleStartedAt.formLabel)",
          inputPlaceholder: "$t(cultures.cycleStartedAt.inputPlaceholder)",
          filterInputPlaceholder: "Filtra per data di semina o inizio ciclo"
        },
        cycleEndedAt: {
          label: "$t(cultures.cycleEndedAt.label)",
          tableHeader: "Data fine ciclo",
          formLabel: "$t(cultures.cycleEndedAt.formLabel)",
          inputPlaceholder: "$t(cultures.cycleEndedAt.inputPlaceholder)",
          filterInputPlaceholder: "Filtra per data di fine ciclo"
        },
        cultivatedCulture: {
          fieldSetLabel: "$t(cultures.cultivatedCulture.fieldSetLabel)",
          formLabel: "$t(cultures.cultivatedCulture.formLabel)",
          "name.it": {
            label: "Coltura",
            tableHeader: "$t(management.cultures.cultivatedCulture.name.it.label)",
            filterInputPlaceholder: "Filtra per cultura"
          },
          isHerbaceous: {
            label: "$t(definitions.cultures.isHerbaceous.label)",
            tableHeader: "$t(definitions.cultures.isHerbaceous.tableHeader)",
            filterInputPlaceholder: "$t(definitions.cultures.isHerbaceous.filterInputPlaceholder)"
          }
        },
        "cultivatedCultureVariety.name.it": {
          label: "Varietà",
          tableHeader: "$t(management.cultures.cultivatedCultureVariety.name.it.label)",
          filterInputPlaceholder: "Filtra per varietà"
        },
        field: {
          label: "Campo",
          formLabel: "$t(management.cultures.field.label)",
          inputPlaceholder: "Seleziona campo",
          name: {
            label: "$t(management.cultures.field.label)",
            tableHeader: "$t(management.cultures.field.label)",
            filterInputPlaceholder: "Filtra per campo"
          },
          "group.name": {
            label: "Gruppo di campo",
            tableHeader: "$t(management.cultures.field.group.name.label)",
            filterInputPlaceholder: "Filtra per gruppo di campo"
          },
          area: {
            label: "Superficie del campo (m²)",
            tableHeader: "$t(management.cultures.field.area.label)"
          },
          areaHa: {
            label: "Superficie del campo (ha)",
            tableHeader: "$t(management.cultures.field.areaHa.label)"
          }
        },
        area: {
          label: "Percentuale di superficie coltivata",
          tableHeader: "$t(cultures.area.label) (%)",
          formLabel: "$t(management.cultures.area.label)",
          inputPlaceholder: "$t(cultures.area.inputPlaceholder)"
        },
        areaMq: {
          label: "$t(cultures.area.label) (m²)",
          tableHeader: "$t(cultures.area.label) (m²)"
        },
        areaHa: {
          label: "$t(cultures.area.label) (ha)",
          tableHeader: "$t(cultures.area.label) (ha)"
        },
        seedCost: {
          label: "$t(cultures.seedCost.label)",
          tableHeader: "$t(cultures.seedCost.label)",
          filterInputPlaceholder: "Filtra per il costo del seme"
        },
        plantCost: {
          label: "$t(cultures.plantCost.label)",
          tableHeader: "$t(cultures.plantCost.label)",
          filterInputPlaceholder: "Filtra per il costo della pianta"
        },
        expectedYield: {
          label: "$t(cultures.expectedYield.label)",
          tableHeader: "$t(cultures.expectedYield.label)",
          filterInputPlaceholder: "Filtra per resa prevista"
        },
        yield: {
          label: "$t(cultures.yield.label)",
          tableHeader: "$t(cultures.yield.label)",
          filterInputPlaceholder: "Filtra per resa"
        },
        expectedSalesPrice: {
          label: "$t(cultures.expectedSalesPrice.label)",
          tableHeader: "$t(cultures.expectedSalesPrice.label)",
          filterInputPlaceholder: "Filtra per prezzo di vendita previsto"
        },
        salesPrice: {
          label: "$t(cultures.salesPrice.label)",
          tableHeader: "$t(cultures.salesPrice.label)",
          filterInputPlaceholder: "Filtra per data di inizio ciclo"
        },
        rowsDistance: {
          label: "$t(cultures.rowsDistance.label)",
          tableHeader: "$t(cultures.rowsDistance.label)",
          filterInputPlaceholder: "Filtra per la distanza tra le file"
        },
        plantsDistance: {
          label: "$t(cultures.plantsDistance.label)",
          tableHeader: "$t(cultures.plantsDistance.label)",
          filterInputPlaceholder: "Filtra per la distanza tra le piante"
        },
        rowsInClusters: {
          label: "$t(cultures.rowsInClusters.label)",
          tableHeader: "$t(cultures.rowsInClusters.label)",
          filterInputPlaceholder: "Filtra per numero di file nelle bine"
        },
        distanceBetweenClusters: {
          label: "$t(cultures.distanceBetweenClusters.label)",
          tableHeader: "$t(cultures.distanceBetweenClusters.label)",
          filterInputPlaceholder: "Filtra per distanza tra le bine"
        },
        plantingSpacing: {
          label: "$t(cultures.plantingSpacing.label)",
          tableHeader: "$t(cultures.plantingSpacing.label)",
          filterInputPlaceholder: "Filtra per sesto d'impianto"
        },
        plantedUnits: {
          label: "$t(cultures.plantedUnits.label)",
          tableHeader: "$t(cultures.plantedUnits.label)",
          formLabel: "$t(cultures.plantedUnits.formLabel)",
          inputPlaceholder: "$t(cultures.plantedUnits.inputPlaceholder)"
        },
        experimental: {
          label: "Tipologia",
          tableHeader: "Tipologia",
          formLabel: "$t(cultures.experimental.formLabel)",
          inputPlaceholder: "$t(cultures.experimental.inputPlaceholder)",
          filterInputPlaceholder: "Filtra per tipologia"
        },
        status: {
          label: "$t(cultures.status.label)",
          tableHeader: "$t(cultures.status.label)",
          formLabel: "$t(cultures.status.label)",
          inputPlaceholder: "Seleziona uno stato per la coltura",
          filterInputPlaceholder: "Filtra per stato della coltura"
        },
        tenant: {
          label: "Cliente",
          formLabel: "$t(management.cultures.tenant.label)",
          inputPlaceholder: "Seleziona cliente",
          name: {
            label: "$t(management.cultures.tenant.label)",
            tableHeader: "$t(management.cultures.tenant.label)",
            filterInputPlaceholder: "Filtra per cliente"
          }
        },
        "irrigation.method.name.it": {
          label: "$t(cultures.irrigation.method.label)"
        },
        "irrigation.source.name.it": {
          label: "$t(cultures.irrigation.source.label)"
        },
        updatedBy: {
          label: "Aggiornato Da",
          tableHeader: "$t(management.cultures.updatedBy.label)",
          filterInputPlaceholder: "Filtra per utente"
        },
        updatedAt: {
          label: "Aggiornato Il",
          tableHeader: "$t(management.cultures.updatedAt.label)",
          filterInputPlaceholder: "Filtra per data aggiornamento"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      fields: {
        label: "$t(fields.label)",
        label_other: "$t(fields.label_other)",
        createLabel: "$t(fields.createLabel)",
        updateLabel: "$t(fields.updateLabel)",
        exportShpLabel: "Scarica shape file",
        id: {
          label: "id",
          tableHeader: "$t(management.cultures.id.label)"
        },
        name: {
          label: "Nome",
          tableHeader: "$t(management.fields.name.label)",
          formLabel: "$t(management.fields.name.label)",
          inputPlaceholder: "Inserisci il nome del campo",
          filterInputPlaceholder: "Filtra per nome del campo"
        },
        group: {
          label: "$t(fields.group.label)",
          formLabel: "$t(fields.group.formLabel)",
          inputPlaceholder: "$t(fields.group.inputPlaceHolder)",
          name: {
            label: "$t(fields.group.label)",
            tableHeader: "$t(fields.group.label)",
            filterInputPlaceholder: "Filtra per gruppo"
          }
        },
        area: {
          label: "$t(fields.area.tableHeader)",
          tableHeader: "$t(management.fields.area.label)",
          formLabel: "$t(management.fields.area.label)",
          inputPlaceholder: "Inserisci la superficie del campo"
        },
        areaHa: {
          label: "Superficie (ha)",
          tableHeader: "$t(management.fields.areaHa.label)"
        },
        address: {
          formatted: {
            label: "$t(fields.address.label)",
            tableHeader: "$t(fields.address.label)"
          }
        },
        "soilGranulometry.name.it": {
          label: "$t(fields.soilGranulometry.label)"
        },
        "coverageType.name.it": {
          label: "$t(fields.coverageType.label)"
        },
        "type.name.it": {
          label: "$t(fields.type.label)",
          tableHeader: "$t(fields.type.label)"
        },
        productionBatchCode: {
          label: "$t(fields.productionBatchCode.label)",
          tableHeader: "$t(fields.productionBatchCode.label)",
          filterInputPlaceholder: "$t(fields.productionBatchCode.filterInputPlaceholder)"
        },
        tenant: {
          label: "Cliente",
          formLabel: "$t(management.cultures.tenant.label)",
          inputPlaceholder: "Seleziona cliente",
          name: {
            label: "$t(management.cultures.tenant.label)",
            tableHeader: "$t(management.cultures.tenant.label)",
            filterInputPlaceholder: "Filtra per cliente"
          }
        },
        updatedBy: {
          label: "Aggiornato Da",
          tableHeader: "$t(management.cultures.updatedBy.label)",
          filterInputPlaceholder: "Filtra per utente"
        },
        updatedAt: {
          label: "Aggiornato Il",
          tableHeader: "$t(management.cultures.updatedAt.label)",
          filterInputPlaceholder: "Filtra per data aggiornamento"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        },
        soilProperties: {
          soilTexture: {
            label: "$t(fields.soilProperties.soilTexture.formLabel)"
          },
          clay: {
            label: "$t(fields.soilProperties.clay.formLabel) (%Wt)"
          },
          sand: {
            label: "$t(fields.soilProperties.sand.formLabel) (%Wt)"
          },
          silt: {
            label: "Limo (%Wt)"
          },
          organicMatter: {
            label: "$t(fields.soilProperties.organicMatter.formLabel) (%Wt)"
          },
          compaction: {
            label: "$t(fields.soilProperties.compaction.formLabel)"
          },
          electricalConductivity: {
            label: "$t(fields.soilProperties.electricalConductivity.formLabel) dS/m"
          },
          gravel: {
            label: "$t(fields.soilProperties.gravel.formLabel) (%Wt)"
          },
          fieldCapacity: {
            label: "$t(fields.soilProperties.fieldCapacity.formLabel) (%Vol)"
          },
          wiltingPoint: {
            label: "$t(fields.soilProperties.wiltingPoint.formLabel) (%Vol)"
          },
          saturation: {
            label: "$t(fields.soilProperties.saturation.formLabel) (%Vol)"
          },
          availableWater: {
            label: "$t(fields.soilProperties.availableWater.formLabel) (%Vol)"
          },
          saturatedHydraulicConductivity: {
            label: "$t(fields.soilProperties.saturatedHydraulicConductivity.formLabel) (mm/h)"
          },
          bulkDensity: {
            label: "$t(fields.soilProperties.bulkDensity.formLabel) (g/cm³)"
          },
          ph: {
            label: "$t(fields.soilProperties.ph.formLabel)"
          },
          activeLimestone: {
            label: "$t(fields.soilProperties.activeLimestone.formLabel) (%Wt)"
          },
          potassiumOxide: {
            label: "$t(fields.soilProperties.potassiumOxide.formLabel) (%Wt)"
          },
          phosphoricAnhydride: {
            label: "$t(fields.soilProperties.phosphoricAnhydride.formLabel) (%Wt)"
          }
        },
        iotDevices: {
          id: {
            tableHeader: "Dispositivi",
            filterInputPlaceholder: "Filtra per i dispositivi associati al campo"
          }
        }
      }
    },
    activities: {
      label: "Agenda di campagna",
      createLabel: "Crea attività",
      updateLabel: "Modifica attività",
      sowingSectionSeedBoxHeader: "Quantitativo di seme da distribuire per ettaro",
      viewLabel: "Operazione",
      product: "Prodotto {{index}}",
      addProductBtn: "Aggiungi prodotto",
      fertilizationBadgeTitle: "Distribuzione di unità di fertilizzante",
      noFertilizers: "Nessun fertilizzante è stato selezionato per questa attività",
      noHerbicides: "Nessun diserbante è stato selezionato per questa attività",
      noDefenseProducts: "Nessun prodotto per la difesa è stato selezionato per questa attività",
      type: {
        selectHeader: "Seleziona attività",
        sowing: "Semina",
        transplant: "Trapianto",
        irrigation: "Irrigazione",
        fertilization: "Concimazione",
        defense: "Difesa",
        weeding: "Diserbo",
        harvest: "Raccolta",
        formLabel: "Attività",
        inputPlaceholder: "Seleziona l'attività"
      },
      subject: {
        formLabel: "Campo",
        formLabelMulti: "Campi",
        inputPlaceholder: "Seleziona il campo",
        inputPlaceholderMulti: "Seleziona i campi"
      },
      bbchMainStage: {
        label: "Fase principale BBCH",
        formLabel: "$t(activities.bbchMainStage.label)",
        inputPlaceholder: "Seleziona la fase principale BBCH"
      },
      bbchSecondaryStage: {
        label: "Fase secondaria BBCH",
        formLabel: "$t(activities.bbchSecondaryStage.label)",
        inputPlaceholder: "Seleziona la fase secondaria BBCH"
      },
      startDate: {
        formLabel: "Inizio attività",
        inputPlaceholder: "Inserisci data/ora di inizio"
      },
      endDate: {
        formLabel: "Fine attività",
        inputPlaceholder: "Inserisci data/ora di fine"
      },
      completed: {
        formLabel: "Stato",
        true: "Fatta",
        false: "Da fare"
      },
      assignedTo: {
        formLabel: "Assegnata a",
        inputPlaceholder: "Seleziona gli utenti a cui assegnare l'attività"
      },
      culture: {
        category: {
          inputPlaceholder: "Coltura attiva selezionata"
        },
        variety: {
          inputPlaceholder: "Varietà selezionata"
        }
      },
      seedBatchNumber: {
        formLabel: "Lotto del seme",
        inputPlaceholder: "Inserisci il lotto del seme"
      },
      isSeedGMOFree: {
        formLabel: "Lotto OGM free",
        inputPlaceholder: "Confermo di avere il lotto OGM free"
      },
      isSeedCoated: {
        formLabel: "Concia",
        true: "Sì",
        false: "No"
      },
      coatingProduct: {
        formLabel: "Prodotto usato per la concia",
        inputPlaceholder: "Inserisci il nome del prodotto"
      },
      coatingActiveSubstances: {
        formLabel: "Sostanze attive",
        inputPlaceholder: "Inserisci le sostanze attive contenute nel prodotto"
      },
      sowingDensity: {
        formLabel: "Densità di semina",
        inputPlaceholder: "Inserisci la densità di semina"
      },
      seedGerminability: {
        formLabel: "Germinabilità",
        inputPlaceholder: "Inserisci la germinabilità"
      },
      seedWeight: {
        formLabel: "Peso del seme",
        inputPlaceholder: "Inserisci il peso del seme"
      },
      actualSowingDensity: {
        formLabel: "Densità di semina effettiva"
      },
      actualSowingRate: {
        formLabel: "Dose di semina effettiva"
      },
      totalWaterVolume: {
        formLabel: "Volume d'acqua totale",
        inputPlaceholder: "Inserisci il volume"
      },
      basicFertilization: {
        inputPlaceholder: "Concimazione di fondo"
      },
      waterVolumePerHa: {
        formLabel: "Volume d'acqua per ettaro da utilizzare",
        inputPlaceholder: "Inserisci il volume d'acqua per ettaro da utilizzare"
      },
      distributionMethod: {
        formLabel: "Metodo di distribuzione",
        inputPlaceholder: "Seleziona il metodo di distribuzione"
      },
      dosagePerHa: {
        calcSectionLabel: "Calcolo dosaggio per trattamento",
        dosageToUseLabel: "Dose da utilizzare",
        formLabel: "Dose di prodotto per ettaro",
        inputPlaceholder: "Inserisci la dose di prodotto per ettaro"
      },
      detectedAdversities: {
        formLabel: "Avversità",
        inputPlaceholder: "Seleziona le avversità rilevate"
      },
      treatmentMethod: {
        formLabel: "Metodo di trattamento",
        inputPlaceholder: "Seleziona il metodo di trattamento"
      },
      treatmentReason: {
        formLabel: "Motivo del trattamento",
        inputPlaceholder: "Seleziona il motivo del trattamento"
      },
      interventionPeriod: {
        formLabel: "Epoca d'intervento",
        inputPlaceholder: "Seleziona l'epoca d'intervento"
      },
      bbchInfestingStage: {
        formLabel: "Fase fenologica infestante BBCH",
        inputPlaceholder: "Inserisci la fase fenologica infestante"
      },
      safetyDaysInterval: {
        formLabel: "Intervallo di sicurezza",
        inputPlaceholder: "Inserisci intervallo"
      },
      returnDays: {
        formLabel: "Tempo di rientro",
        inputPlaceholder: "Inserisci tempo"
      },
      waitingPeriod: {
        formLabel: "Fine del termine di carenza"
      },
      wasTechnicalAuthorizationRespected: {
        formLabel: "Autorizzazione tecnica",
        inputPlaceholder: "Confermo che l'autorizzazione tecnica è stata rispettata"
      },
      wasBufferZoneRespected: {
        formLabel: "Fascia di rispetto",
        inputPlaceholder: "Confermo che la fascia di rispetto è stata rispettata"
      },
      harvestingMethod: {
        formLabel: "Metodo di raccolta",
        inputPlaceholder: "Seleziona il metodo di raccolta"
      },
      harvestedQuantity: {
        formLabel: "Quantità totale",
        inputPlaceholder: "Inserici la quantità totale raccolta"
      },
      residuesDestination: {
        formLabel: "Destinazione dei residui",
        inputPlaceholder: "Seleziona la destinazione dei residui"
      },
      notes: {
        formLabel: "Note",
        inputPlaceholder: "Aggiungi informazioni aggiuntive sull'attività"
      }
    },
    fertilizers: {
      label: "Concime",
      label_other: "Concimi",
      createLabel: "Crea $t(fertilizers.label)",
      updateLabel: "Modifica $t(fertilizers.label)",
      createLabelAlt: "Crea prodotto",
      name: {
        label: "Nome",
        tableHeader: "$t(fertilizers.name.label)",
        formLabel: "Nome prodotto",
        inputPlaceholder: "Inserisci il nome del prodotto",
        filterInputPlaceholder: "Filtra per il nome del prodotto"
      },
      category: {
        label: "Categoria",
        tableHeader: "$t(fertilizers.category.label)",
        formLabel: "Categoria prodotto",
        inputPlaceholder: "Seleziona categoria prodotto",
        filterInputPlaceholder: "Filtra per categoria prodotto"
      },
      isLiquid: {
        label: "Stato",
        tableHeader: "$t(fertilizers.isLiquid.label)",
        formLabel: "Liquido/Solido",
        filterInputPlaceholder: "Filtra per stato",
        true: "Liquido",
        false: "Solido"
      },
      nitrogen: {
        label: "Azoto % (N)",
        tableHeader: "N (%)",
        formLabel: "Azoto (N)",
        inputPlaceholder: "Inserisci la percentuale di azoto",
        filterInputPlaceholder: "Filtra per percentuale di azoto"
      },
      phosphoricAnhydride: {
        label: "Anidride fosforica % (P₂O₅)",
        tableHeader: "P₂O₅ (%)",
        formLabel: "Anidride fosforica (P₂O₅)",
        inputPlaceholder: "Inserisci la percentuale di anidride fosforica",
        filterInputPlaceholder: "Filtra per percentuale di anidride fosforica"
      },
      potassiumOxide: {
        label: "Ossido di potassio % (K₂O)",
        tableHeader: "K₂O (%)",
        formLabel: "Ossido di potassio (K₂O)",
        inputPlaceholder: "Inserisci la percentuale di ossido di potassio",
        filterInputPlaceholder: "Filtra per percentuale di ossido di potassio"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(fertilizers.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(fertilizers.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    defenseProducts: {
      label: "Prodotto per la difesa",
      label_other: "Prodotti per la difesa",
      createLabel: "Aggiungi $t(defenseProducts.label)",
      updateLabel: "Modifica $t(defenseProducts.label)",
      createLabelAlt: "Aggiungi prodotto",
      name: {
        label: "Nome",
        tableHeader: "$t(defenseProducts.name.label)",
        formLabel: "Nome prodotto",
        inputPlaceholder: "Inserisci il nome del prodotto",
        filterInputPlaceholder: "Filtra per il nome del prodotto",
        prompt: "Ricerca il prodotto di difesa..."
      },
      registrationNumber: {
        label: "Numero di registrazione",
        tableHeader: "Num. registrazione",
        filterInputPlaceholder: "Filtra per il numero di registrazione"
      },
      categories: {
        "name.it": {
          label: "Categoria prodotto",
          tableHeader: "$t(defenseProducts.categories.name.it.label)",
          filterInputPlaceholder: "Filtra per categoria di prodotto"
        },
        formLabel: "$t(defenseProducts.categories.name.it.label)",
        inputPlaceholder: "Seleziona almeno una categoria per il prodotto"
      },
      activeSubstances: {
        label: "Sostanze attive",
        tableHeader: "$t(defenseProducts.activeSubstances.label)",
        formLabel: "$t(defenseProducts.activeSubstances.label)",
        inputPlaceholder: "Inserisci le sostanze attive",
        filterInputPlaceholder: "Filtra per le sostanze attive"
      },
      hazardWarnings: {
        label: "Indicazioni di pericolo",
        tableHeader: "Indicaz. di pericolo",
        formLabel: "$t(defenseProducts.hazardWarnings.label)",
        inputPlaceholder: "Inserisci le indicazioni di pericolo",
        filterInputPlaceholder: "Filtra per le indicazioni di pericolo"
      },
      isLiquid: {
        label: "Composizione",
        tableHeader: "$t(defenseProducts.isLiquid.label)",
        formLabel: "$t(defenseProducts.isLiquid.label)",
        filterInputPlaceholder: "Filtra per tipo di composizione",
        true: "Liquida",
        false: "Solida"
      },
      registrationDate: {
        label: "Data registrazione",
        tableHeader: "$t(defenseProducts.registrationDate.label)",
        filterInputPlaceholder: "Filtra per data di registrazione"
      },
      administrativeStatus: {
        label: "Stato amministrativo",
        tableHeader: "$t(defenseProducts.administrativeStatus.label)",
        filterInputPlaceholder: "Filtra per stato amministrativo"
      },
      revocationDate: {
        label: "Data revoca",
        tableHeader: "$t(defenseProducts.revocationDate.label)",
        filterInputPlaceholder: "Filtra per data di revoca"
      },
      effectiveRevocationDate: {
        label: "Decorrenza revoca",
        tableHeader: "$t(defenseProducts.effectiveRevocationDate.label)",
        filterInputPlaceholder: "Filtra per data di decorrenza revoca"
      },
      isUsable: {
        label: "Stato",
        tableHeader: "$t(defenseProducts.isUsable.label)",
        filterInputPlaceholder: "Filtra per stato",
        true: "Utilizzabile",
        false: "Non utilizzabile"
      },
      stockDisposalDate: {
        label: "Data smaltimento scorte",
        tableHeader: "$t(defenseProducts.stockDisposalDate.label)",
        filterInputPlaceholder: "Filtra per data di smaltimento scorte"
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(defenseProducts.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(defenseProducts.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "product-categories": {
      label: "Categoria di prodotto",
      label_other: "Categorie di prodotti",
      createLabel: "Aggiungi $t(product-categories.label)",
      updateLabel: "Modifica $t(product-categories.label)",
      id: {
        label: "Id",
        tableHeader: "$t(product-categories.id.label)",
        formLabel: "$t(product-categories.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Nome",
        it: {
          label: "Nome Italiano",
          tableHeader: "$t(product-categories.name.it.label)",
          formLabel: "Italiano",
          inputPlaceholder: "Inserisci il nome in italiano",
          filterInputPlaceholder: "Filtra per nome (italiano)"
        },
        en: {
          label: "Nome Inglese",
          tableHeader: "$t(product-categories.name.en.label)",
          formLabel: "Inglese",
          inputPlaceholder: "Inserisci il nome in inglese",
          filterInputPlaceholder: "Filtra per nome (inglese)"
        }
      },
      updatedBy: {
        label: "Aggiornato Da",
        tableHeader: "$t(product-categories.updatedBy.label)",
        filterInputPlaceholder: "Filtra per utente"
      },
      updatedAt: {
        label: "Aggiornato Il",
        tableHeader: "$t(product-categories.updatedAt.label)",
        filterInputPlaceholder: "Filtra per data aggiornamento"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    }
  },
  maps: {
    geoAutocomplete: {
      placeholder: "Inserisci indirizzo"
    },
    polygon: {
      clear: "Rimuovi forme"
    },
    layers: {
      menuLabel: "Sovrapponi livelli",
      overlaysHeader: "Visualizza elementi",
      fields: "Campi",
      devices: "Dispositivi",
      cadastre: "Mappa catastale",
      satIndexesHeader: "Indici satellitari",
      satIndexImageDate: "Data",
      satIndexImageDatePlaceholder: "Seleziona data disponibile",
      satIndex: "Indice",
      satIndexPlaceholder: "Seleziona indice satellitare",
      satIndexNull: `Non è stato trovato nessun risultato per "{{index}}"`,
      satIndexReqError: "Non è possibile mostrare il livello selezionato, si è verificato un errore nella richiesta ",
      ndvi: "Indice di vegetazione (NDVI)",
      evi: "Indice di vegetazione migliorato (EVI)",
      swir: "Contenuto idrico del suolo",
      smi: "Contenuto idrico del suolo (SMI)",
      ndmi: "Contenuto idrico della vegetazione (NDMI)",
      yield: "Resa potenziale (YIELD)",
      true_color: "True color",
      chlorophyll: "Deficit di azoto",
      nitro: "Concime (Compensazione)",
      satIndexDateRange: "Da/a",
      satIndexResolution: "Risoluzione in metri",
      satIndexResolutionPlaceholder: "Seleziona risoluzione",
      satIndexShowBtnLabel: "Mostra indice satellitare",
      nitrogen_deficit: "$t(layers.chlorophyll)",
      et_sentinel2: "Evapotraspirazione (Sentinel 2)",
      etSentinelMeasureUnit: "mm/gg",
      et_modis: "Evapotraspirazione (Modis)",
      etModisMeasureUnit: "mm/8gg",
      percentage: "Mostra valore in percentuale",
      min: "Min",
      max: "Max",
      mean: "Media",
      stdDev: "Dev. Std.",
      coverage: "Copertura",
      cloudiness: "Nuvolosità",
      cloudinessWarning: "Nessun dato trovato per la data selezionata. Nuvolosità al {{cloudiness}}%",
      sensorSelectPlaceholder: "Seleziona un sensore",
      yieldSensorSelectLabel: "Sensore di umidità e temperatura",
      nitroSensorSelectLabel: "Sensore di umidità del suolo"
    },
    drawings: {
      label: "Disegna",
      edit: "Modifica",
      delete: "Elimina",
      confirm: "Conferma"
    }
  },
  gallery: {
    deleteBtn: "Cancella immagini",
    upload: {
      formLabel: "Trascina o clicca per caricare le tue immagini, oppure scatta una foto.",
      btn: "Carica immagini"
    },
    delete: {
      question: "Sei sicuro di voler cancellare questa foto?",
      message: "Una volta cancellata, la foto non potrà essere recuperata.",
      ok: "Elimina"
    }
  }
};
