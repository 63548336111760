import { Icon } from "app/components/icon";
import { getCurrentLocale } from "app/utils";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { ForwardedRef, forwardRef, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Notification } from "./types";

type NotificationItemProps = Readonly<{
  notification: Notification;
  onNotificationItemClick: MouseEventHandler<HTMLDivElement>;
}>;

function formatDate(isoString: string, language: Lang): string {
  const locale = getCurrentLocale(language);
  const date = parseISO(isoString);
  let pattern = "d MMM yy'<br/><span>'p'</span>'";
  return format(date, pattern, { locale });
}

export const NotificationItem = forwardRef(
  ({ notification, onNotificationItemClick }: NotificationItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { i18n } = useTranslation();
    const { id, read, type, createdAt } = notification;
    const createdAtFormatted = formatDate(createdAt, i18n.language as Lang);
    const iconName =
      type === "info" ? "bi-info-circle" : type === "warning" ? "bi-exclamation-triangle" : "bi-lightning-charge";
    const iconTextColor = type === "info" ? "text-secondary" : type === "warning" ? "text-warning" : "text-danger";

    return (
      <div id={String(id)} className="notification-item" role="button" onClick={onNotificationItemClick} ref={ref}>
        <div className="content">
          <div className="title">
            <p className={read ? "text-secondary" : "fw-bold text-black"}>{notification.title}</p>
          </div>
          <div className="description clamped">
            <p className="text-secondary">{notification?.description ?? ""}</p>
          </div>
        </div>
        <div className="info">
          <Icon name={iconName} className={iconTextColor} />
          <p className="text-secondary text-center" dangerouslySetInnerHTML={{ __html: createdAtFormatted }} />
        </div>
      </div>
    );
  }
);
