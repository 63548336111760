import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { filterLutAtom, NotificationFilters, searchBarValueAtom, sortDirectionAtom } from "../store";

function getReadFilter(filters: NotificationFilters, params: Record<string, string>): void {
  const lut: { [key: string]: string } = { read: "true", unread: "false" };
  const keys = Object.entries(filters)
    .filter(([key, value]) => (key === "read" || key === "unread") && value)
    .map(([key]) => key);
  if (keys.length >= 2) return;
  params.read = keys.length === 0 ? "null" : lut[keys[0]];
}

function getTypeFilter(filters: NotificationFilters, params: Record<string, string>): void {
  const keys = Object.entries(filters)
    .filter(([key, value]) => key !== "read" && key !== "unread" && value)
    .map(([key]) => key);
  if (keys.length >= 3) return;
  params.type = keys.length === 0 ? "null" : `:${keys.join(",,")}`;
}

export function useNotificationSearchParams() {
  const { i18n } = useTranslation();
  const sort = useAtomValue(sortDirectionAtom);
  const filters = useAtomValue(filterLutAtom);
  const search = useAtomValue(searchBarValueAtom);

  return useMemo(() => {
    const object: Record<string, string> = {
      sort: `createdAt,${sort}`
    };

    getReadFilter(filters, object);
    getTypeFilter(filters, object);

    if (search !== "") {
      object[`description.${i18n.language as Lang}`] = `*${search}*`;
    }

    return object;
  }, [filters, i18n.language, search, sort]);
}
