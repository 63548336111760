import { getKeycloakInstanceProp } from "app/auth";
import { api } from "app/config";
import i18next from "app/i18n";
import Axios, { InternalAxiosRequestConfig } from "axios";

export const axios = Axios.create({
  baseURL: api,
  validateStatus: (status) => status < 400
});

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    try {
      const isTokenExpired = getKeycloakInstanceProp("isTokenExpired") as Function;
      const updateToken = getKeycloakInstanceProp("updateToken") as Function;
      if (!config.url.includes("idp")) {
        if (isTokenExpired(0)) updateToken(0);
        // console.info(`Sending authenticated request to ${config.url} with method ${config.method}`);
        const realm = getKeycloakInstanceProp("realm");
        const token = getKeycloakInstanceProp("token");
        config.headers["X-Tenant-Name"] = realm as string;
        config.headers["Authorization"] = `Bearer ${token}` as string;
        config.headers["X-Client-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    } catch (error: unknown) {
      // console.error(error);
    }
    config.headers["Accept-Language"] = i18next.resolvedLanguage;
    config.headers["Content-Type"] = config.headers.getContentType() ?? "application/json";
    return config;
  },
  (error: any) => Promise.reject(error)
);
