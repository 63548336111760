import { useAtom } from "jotai";
import { sortDirectionAtom } from "../store";

export function useSortDirection() {
  const [sortDirection, setSortDirection] = useAtom(sortDirectionAtom);
  const changeSortDirection = () => {
    setSortDirection((sortDirection) => (sortDirection === "asc" ? "desc" : "asc"));
  };

  return { sortDirection, changeSortDirection };
}
