import { atomWithReset } from "jotai/utils";

export type NotificationFilters = {
  unread: boolean;
  read: boolean;
  info: boolean;
  warning: boolean;
  alert: boolean;
};

export const filterLutAtom = atomWithReset<NotificationFilters>({
  unread: true,
  read: true,
  info: true,
  warning: true,
  alert: true
});

export const sortDirectionAtom = atomWithReset<"asc" | "desc">("desc");

export const searchBarValueAtom = atomWithReset<string>("");
