import { resources } from "app/assets/languages";
import { availableLanguages } from "app/config";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// @ts-ignore
import ReactPostProcessor from "i18next-react-postprocessor";
import { initReactI18next } from "react-i18next";
import countries from "i18n-iso-countries";

// date-locales
import it from "date-fns/locale/it";
import en from "date-fns/locale/en-US";
import { registerLocale, setDefaultLocale } from "react-datepicker";
registerLocale("en", en);
registerLocale("it", it);
setDefaultLocale("it");

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/it.json"));

i18next
  .use(LanguageDetector)
  .use(new ReactPostProcessor())
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      // order and from where user language should be detected
      order: ["localStorage", "sessionStorage", "navigator"],
      // keys or params to lookup language from
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      // cache user language on
      caches: ["localStorage", "sessionStorage"],
      excludeCacheFor: ["cimode"] // languages to not persist (cookie, localStorage)
    },
    fallbackLng: "en",
    supportedLngs: availableLanguages.map((obj) => obj.language),
    ns: ["commons", "interface", "resources", "maps", "gallery"],
    defaultNS: "interface",
    fallbackNS: ["resources", "commons", "maps", "gallery"],
    interpolation: {
      escapeValue: false // react is safe from xss https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export type Lang = "it" | "en";

export const dateLocales: Record<Lang, Locale> = {
  it: it,
  en: en
};
export const i18n_countries = countries;
export default i18next;
