import { useAtom } from "jotai";
import { ChangeEvent } from "react";
import { filterLutAtom } from "../store";

export function useFilters() {
  const [filters, setFilters] = useAtom(filterLutAtom);
  const changeFilterValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name } = target;
    const key: "unread" | "read" | "info" | "warning" | "alert" = name as
      | "unread"
      | "read"
      | "info"
      | "warning"
      | "alert";
    setFilters((filters) => {
      const updated = { ...filters };
      updated[key] = !updated[key];
      return updated;
    });
  };

  return { filters, changeFilterValue };
}
