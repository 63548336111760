import { api } from "app/config";
import { useHttpRequest } from "app/hooks";
import { useQuery } from "react-query";

type NotificationCountResponse = {
  readonly unreadNotifications: number;
};

export function useNotificationCount(cacheTimeInSeconds: number) {
  const cacheTime = (Number.isInteger(cacheTimeInSeconds) ? cacheTimeInSeconds : +cacheTimeInSeconds.toFixed(0)) * 1000;
  const request = useHttpRequest<void, NotificationCountResponse>();
  const {
    data: count,
    isFetching,
    isLoading
  } = useQuery(
    ["notification-count"],
    async () => {
      // Replace with call to backend!
      const { data } = await request({ url: `${api}/notifications/count`, method: "GET" }, false);
      return data;
    },
    {
      cacheTime,
      refetchInterval: cacheTime,
      select({ unreadNotifications }: NotificationCountResponse) {
        return unreadNotifications;
      }
    }
  );

  return { notificationCount: count, isReady: !isFetching && !isLoading };
}
