import { useCallback, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { useHttpRequest } from "./useHttpRequest";

export type UseInfiniteDataQueryOptions<T> = {
  url: string;
  namespace: string;
  defaultSearchParams?: URLSearchParams;
  onSuccess?: (data: T[]) => void;
  cacheTime?: number;
  staleTime?: number;
};

export function useInfiniteDataQuery<T, R extends HTMLElement>({
  namespace,
  defaultSearchParams = null,
  onSuccess,
  cacheTime = null,
  staleTime = null,
  ...options
}: UseInfiniteDataQueryOptions<T>) {
  const request = useHttpRequest<void, Page<T>>();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteQuery({
    queryKey: [namespace, defaultSearchParams.toString()],
    queryFn: async ({ pageParam }) => {
      const cloned = new URLSearchParams(defaultSearchParams);
      cloned.append("page", pageParam ? pageParam : 0);

      const { data } = await request(
        {
          method: "GET",
          url: options.url,
          params: cloned
        },
        false
      );

      const { content, page } = data;
      const { number, totalPages } = page;

      return {
        data: content,
        nextPage: number + 1 < totalPages ? number + 1 : null
      };
    },
    onSuccess: (result) => {
      if (onSuccess) {
        const { pages } = result ?? {};
        onSuccess((pages ?? []).flatMap((item) => item.data));
      }
    },
    getNextPageParam: (lastPage, _) => lastPage.nextPage,
    cacheTime,
    staleTime
  });

  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useCallback(
    (node: R) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, isFetching, fetchNextPage]
  );

  return { data, isFetching: isFetching || isLoading, lastItemRef, refetch };
}
