import { api } from "app/config";
import { useHttpRequest } from "app/hooks";
import { useMutation, useQueryClient } from "react-query";
import { searchBarValueAtom } from "../store";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";

export function useNotificationReader(reloadNotifications = true) {
  const { i18n } = useTranslation();
  const searchQuery = useAtomValue(searchBarValueAtom);
  const queryClient = useQueryClient();
  const request = useHttpRequest<void, void>();

  const { mutateAsync: notificationReader } = useMutation(
    async (id?: number) => {
      let url = `${api}/notifications/${id}/read`;
      const params = new URLSearchParams();
      if (!id) {
        url = `${api}/notifications/read`;
        params.append(`description.${i18n.language as Lang}`, `*${searchQuery}*`);
      }
      await request({ method: "PUT", url, params }, false);
    },
    {
      onSuccess: async () => {
        await queryClient.resetQueries({ queryKey: ["notification-count"], exact: true });
        if (reloadNotifications) {
          await queryClient.resetQueries({ queryKey: ["notifications"], exact: false });
        }
      }
    }
  );

  return notificationReader;
}
