import { Icon } from "app/components/icon";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import { useNotificationReader } from "./hooks/useNotificationReader";

export const MarkAllAsReadButton = () => {
  const { t } = useTranslation();
  const markAllAsRead = useNotificationReader();

  const onClick = () => {
    markAllAsRead(undefined).catch((_) => console.error("Failed to mark all notifications as read"));
  };

  return (
    <Button as="a" variant="link" className="read-all" onClick={onClick}>
      <Icon name="bi-check-square" className="me-2" />
      {t("notifications.markAllAsRead")}
    </Button>
  );
};
